import { Optional, Record, String } from 'runtypes'

export type SupportBannerContainerProps = {
  props: {
    des: string
    buttonLabel: string
    buttonHref: string
    leftBackgroundImageUrl?: string
    rightBackgroundImageUrl?: string
  }
}

export type LinkWrapper = {
  children?: React.ReactNode
  url: string
}

export const SupportBannerContainerTypeGuard = Record({
  des: String,
  buttonLabel: String,
  buttonHref: String,
  leftBackgroundImageUrl: Optional(String),
  rightBackgroundImageUrl: Optional(String)
})

export type SupportBannerProps = {
  leftBackgroundImageUrl?: string
  rightBackgroundImageUrl?: string
  des: React.ReactNode
  button: {
    label: string
    href: string
  }
}

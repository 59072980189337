import React from 'react'
import { CenterText, MainContainer, SliderButton } from './styles'
import { NavigationSliderSecondaryProps } from './NavigationSliderSecondary.types'
import { useTranslation } from 'react-i18n-lite'
import { useIsMobile, Icon } from '@yes.technology/react-toolkit'

function NavigationSliderSecondary({
  options,
  currentIndex,
  onNextClick,
  onPrevClick,
  disabled
}: NavigationSliderSecondaryProps) {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const currentOption = options[currentIndex]

  if (!currentOption) {
    return null
  }

  const { value, color } = currentOption
  const previousLabel = `${value} - ${t('navigation-slider.previous')}`
  const nextLabel = `${value} - ${t('navigation-slider.next')}`

  return (
    <MainContainer>
      <SliderButton
        onClick={onPrevClick}
        type='button'
        aria-label={previousLabel}
        disabled={disabled}
      >
        <Icon
          iconName={disabled ? 'NavigationLeftDisabled' : 'NavigationLeft'}
          icSize={isMobile ? 'semiLarge' : 'extraLarge'}
          icColor='blackText'
          // TODO: Remove this when navigation button is created
          style={{ padding: disabled ? '4px' : undefined }}
        />
      </SliderButton>

      <CenterText $color={color}>{value}</CenterText>

      <SliderButton
        onClick={onNextClick}
        type='button'
        aria-label={nextLabel}
        disabled={disabled}
      >
        <Icon
          iconName={disabled ? 'NavigationRightDisabled' : 'NavigationRight'}
          icSize={isMobile ? 'semiLarge' : 'extraLarge'}
          icColor='blackText'
          // TODO: Remove this when navigation button is created
          style={{ padding: disabled ? '4px' : undefined }}
        />
      </SliderButton>
    </MainContainer>
  )
}

export default NavigationSliderSecondary

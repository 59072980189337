import { YesObject } from '@yes.technology/react-toolkit'
import isEmpty from 'lodash/isEmpty'
import { ColumnProps, LayoutProps } from './Kanban.types'

export function filterSectionsByLayout(
  groupedYesObjects: Record<string, YesObject[]>,
  layoutSections: LayoutProps['sections']
) {
  const sectionsGroupedByOverrideAttributes =
    layoutSections?.reduce((acc: Record<string, ColumnProps>, section) => {
      section.columns.forEach((record) => {
        acc[record.grouped_by_value as string] = record
      })

      return acc
    }, {}) ?? {}

  return {
    filteredSections: isEmpty(sectionsGroupedByOverrideAttributes)
      ? groupedYesObjects
      : Object.entries(groupedYesObjects)
          .filter(([columnName]) =>
            Object.keys(sectionsGroupedByOverrideAttributes).includes(
              columnName
            )
          )
          .reduce((acc: Record<string, YesObject[]>, [columnName, values]) => {
            acc[columnName] = values
            return acc
          }, {}),
    columnsOverrideAttributes: sectionsGroupedByOverrideAttributes
  }
}

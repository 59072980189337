import { useCallback, useEffect, useReducer, useState } from 'react'
import { Field } from '@yes.technology/react-toolkit'
import { Form } from 'component'
import { FieldValues, FilterAreaLayout, GroupedFieldValues } from '../../types'
import Section from '../Section/Section'
import { useSiteState } from 'siteState/shared'
import { toYesFilter } from '../../shared/toYesFilter/toYesFilter'
import useLocationState from '../../hooks/useLocationState'

type Action = {
  type?: 'merge' | 'set'
  payload: GroupedFieldValues
}

type FilterFormProps = {
  filterAreaLayout?: FilterAreaLayout
  initialGroupedFieldValues: GroupedFieldValues
  sections: Field[][]
  title?: string
  onSubmit?: (query: ReturnType<typeof toYesFilter>) => void
  uuidCurrentSitemodelObjectclass?: string
  defaultExecutedFilteraction?: string
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
}

const FilterForm = ({
  filterAreaLayout,
  initialGroupedFieldValues,
  sections,
  title,
  onSubmit,
  uuidCurrentSitemodelObjectclass,
  defaultExecutedFilteraction,
  navigationSliderBackgroundColor,
  navigationSliderColor
}: FilterFormProps) => {
  const {
    shouldPersistData,
    setShouldPersistData,
    shouldResetData,
    setShouldResetData,
    persistentSelection
  } = useLocationState()

  const fieldValuesReducer = (
    state: GroupedFieldValues,
    action: Action | null
  ) => {
    if (action === null) {
      return initialGroupedFieldValues
    }

    if (action.type === 'set') {
      return action.payload
    }

    return {
      ...state,
      ...Object.fromEntries(
        Object.entries(action.payload).map(([actionGroupKey, actionGroup]) => [
          actionGroupKey,
          { ...state[actionGroupKey], ...actionGroup }
        ])
      )
    }
  }

  const [groupedFieldValues, handleChange] = useReducer(
    fieldValuesReducer,
    initialGroupedFieldValues
  )

  useEffect(() => {
    if (shouldPersistData && persistentSelection) {
      handleChange({
        type: 'set',
        payload: persistentSelection
      })

      setShouldPersistData(false)
    }
  }, [shouldPersistData, persistentSelection, setShouldPersistData])

  useEffect(() => {
    if (shouldResetData) {
      handleChange(null)

      setShouldResetData(false)
    }
  }, [shouldResetData, setShouldResetData])

  const [composition, handleComposition] = useState('1')

  const [_, setSiteFieldValues] =
    useSiteState<GroupedFieldValues>('field-values')

  const [, setSiteFieldComposition] = useSiteState<string>('field-composition')

  const handleClear = useCallback(() => {
    handleComposition('1')
    handleChange(null)
  }, [])

  const submitFieldValues = useCallback(
    (fieldValues: GroupedFieldValues, composition: string) => {
      const groupedValuesNoEmpty = removeEmptyValuesGrouped(fieldValues)

      const query = toYesFilter(groupedValuesNoEmpty, composition)

      query && onSubmit?.(query)
      query && setSiteFieldValues(groupedValuesNoEmpty)
      setSiteFieldComposition(composition)
    },
    [onSubmit, setSiteFieldValues, setSiteFieldComposition]
  )

  const onSubmitForm = (event: React.FormEvent) => {
    event.preventDefault()
    submitFieldValues(groupedFieldValues, composition)
  }

  const onFieldChange = useCallback((state: GroupedFieldValues) => {
    handleChange({
      type: 'merge',
      payload: state
    })
  }, [])

  return (
    <Form onSubmit={onSubmitForm}>
      <Section
        initialFieldValues={initialGroupedFieldValues[1]}
        handleChange={onFieldChange}
        executeFilter={submitFieldValues}
        {...{
          composition,
          sections: sections,
          groupedFieldValues,
          handleClear,
          handleComposition,
          title,
          uuidCurrentSitemodelObjectclass,
          navigationSliderBackgroundColor,
          navigationSliderColor,
          filterAreaLayout,
          defaultExecutedFilteraction
        }}
      />
    </Form>
  )
}

export function removeEmptyValues<T extends FieldValues>(obj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, item]) =>
      Array.isArray(item.value) ? item.value.length > 0 : item.value
    )
  )
}

function removeEmptyValuesGrouped<T extends GroupedFieldValues>(
  groups: T
): GroupedFieldValues {
  return Object.fromEntries(
    Object.entries(groups).map(([group, values]) => [
      group,
      removeEmptyValues(values)
    ])
  )
}

export default FilterForm

import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import apiAsync from 'infrastructure/shared/api/api-async'
import { HTTPClient } from 'infrastructure/shared/api'
import {
  AuthenticationType,
  PublicSessionToken
} from 'authentication/shared/types/authentication.type'
import { SitemodelUuid } from 'sitemodel/shared/types'

const PrivateRoutes = () => {
  const [searchParams] = useSearchParams()
  const { sitemodelUuid } = useParams<SitemodelUuid>()

  const { token: tokenInState, isLogouting } = useSelector(
    (state: AuthenticationType) => state.authentication
  )

  const tokenInURL = searchParams.get('token')

  const publicSessionToken: PublicSessionToken = window.publicSessionToken

  const token = tokenInURL ?? tokenInState ?? publicSessionToken

  const tokenType =
    !!token && token === publicSessionToken ? 'public' : 'private'

  const publicUrls: string[] = window.publicUrls

  const currentSitemodel = `/${sitemodelUuid ?? ''}`

  const isPublicUrl = publicUrls.includes(currentSitemodel)

  const isAuthenticating = !token || (!isPublicUrl && tokenType === 'public')

  if (isAuthenticating) {
    if (isLogouting && window.postLogoutUrl)
      window.location.assign(window.postLogoutUrl)
    else
      window.location.replace(
        `${window.authSitemodelUuid}?redirectOrigin=${encodeURIComponent(window.location.href)}`
      )
    return null
  }

  apiAsync.setAuthToken(token)
  HTTPClient.setToken(token)

  return <Outlet />
}

export default PrivateRoutes

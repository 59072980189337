import { LinkButton, useIsMobile } from '@yes.technology/react-toolkit'
import {
  Container,
  Content,
  Des,
  Complement,
  ButtonContainer
} from './SupportBannerSecondary.styles'
import { SupportBannerSecondaryProps } from './SupportBannerSecondary.types'

export default function SupportBannerSecondary({
  des,
  complement,
  links
}: SupportBannerSecondaryProps) {
  const isMobile = useIsMobile()
  return (
    <Container>
      <Content>
        <Des>{des}</Des>
        {complement && <Complement>{complement}</Complement>}

        {links && (
          <ButtonContainer>
            {links.map((link) => (
              <LinkButton
                key={link.label}
                variant='secondary'
                href={link.url}
                heightClass={`${isMobile ? 'semiLarge' : 'large'}`}
              >
                {link.label}
              </LinkButton>
            ))}
          </ButtonContainer>
        )}
      </Content>
    </Container>
  )
}

import ImageDocument from 'component/ImageDocument/ImageDocument'
import { ArticleProps } from './article.types'
import {
  ArticleContent,
  ArticleImage,
  ArticleImageDocument,
  ArticleParapgrahs,
  ArticleTitle,
  ButtonsContainer,
  ContentContainer,
  MainContainer
} from './styles'
import { LinkButton, useIsMobile } from '@yes.technology/react-toolkit'

function Article({
  des,
  imageUrl,
  imageAlt,
  complement,
  position,
  buttons,
  ...divProps
}: ArticleProps) {
  const validButtons = buttons?.filter((button) => button.label && button.href)
  const isMobile = useIsMobile()
  return (
    <MainContainer $reverse={position === 'right'} {...divProps}>
      {typeof imageUrl === 'string' && (
        <ArticleImage src={imageUrl} alt={imageAlt} />
      )}

      {typeof imageUrl === 'object' && (
        <ArticleImageDocument>
          <ImageDocument
            documents={imageUrl}
            width={`${isMobile ? '100%' : '432'}`}
          />
        </ArticleImageDocument>
      )}

      <ContentContainer>
        <ArticleTitle>{des}</ArticleTitle>
        <ArticleParapgrahs>
          <ArticleContent>{complement}</ArticleContent>
          {validButtons && validButtons.length > 0 && (
            <ButtonsContainer>
              {validButtons.map((button, index) => (
                <LinkButton key={index} href={button.href} variant='secondary'>
                  {button.label}
                </LinkButton>
              ))}
            </ButtonsContainer>
          )}
        </ArticleParapgrahs>
      </ContentContainer>
    </MainContainer>
  )
}

export default Article

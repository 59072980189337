import { YesObject } from '@yes.technology/react-toolkit'
import { SelectionOption } from '../types'
import useTableContext from './useTableContext'

const useSelection = () => {
  const { selectedRows, setSelectedRows, objects } = useTableContext()

  const getOption = (object: YesObject) => ({
    uuid: object.uuid,
    des: '',
    value: object
  })

  const getCheckedOption = (id: string) =>
    !!selectedRows[id] ? { [id]: selectedRows[id] } : {}

  const onChangeSelection = (row: SelectionOption) => {
    if (selectedRows[row.uuid]) {
      const newSelectedRows = { ...selectedRows }

      delete newSelectedRows[row.uuid]

      setSelectedRows(newSelectedRows)

      return
    }

    setSelectedRows({ ...selectedRows, [row.uuid]: row })
  }

  const selectAllRows = () => {
    const allRows: Record<string, SelectionOption> = {}

    objects.forEach((object) => {
      allRows[object.uuid] = getOption(object)
    })

    setSelectedRows(allRows)
  }

  const deselectAllRows = () => {
    setSelectedRows({})
  }

  return {
    onChangeSelection,
    selectAllRows,
    deselectAllRows,
    getOption,
    getCheckedOption,
    selectedRows
  }
}

export default useSelection

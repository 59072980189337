import { useMemo } from 'react'
import { ComponentPropsType } from 'sitemodel/shared/types/sitemodel.type'
import { fetchYesObjectsWithFilteraction as defaultFetchYesObjectsWithFilteraction } from 'yesObject/shared/api/fetchYesObjectsWithFilteraction'
import useSitemodelState from '../useSitemodelState/useSitemodelState'
import { FilteractionObject } from '@yes.technology/react-toolkit'

const TOKEN_BETWEEN_CURLY_BRACES_REGEX = /\{\{([^}]+)\}\}/g

const parseQuery = (
  query: string,
  state: ComponentPropsType = {}
): {
  interpolatedQuery: string
  dependencies: (string | undefined)[]
} => {
  let interpolatedQuery = query
  const dependencies: (string | undefined)[] = []

  const tokens = query.match(TOKEN_BETWEEN_CURLY_BRACES_REGEX)

  if (!tokens) {
    return { interpolatedQuery, dependencies }
  }

  interpolatedQuery = query.replaceAll(
    TOKEN_BETWEEN_CURLY_BRACES_REGEX,
    (_tokenWithCurlyBraces, token) => {
      const param = state[token]?.toString() ?? ''

      dependencies.push(state[token] === undefined ? undefined : param)

      return param
    }
  )

  return {
    interpolatedQuery,
    dependencies
  }
}

type UsePrepareFilteractionQueriesProps = {
  filteractionDescriptors: {
    [filteractionId: string]: { data?: FilteractionObject }
  }
  fetchYesObjectsWithFilteraction: typeof defaultFetchYesObjectsWithFilteraction
}

export default function usePrepareFilteractionQueries({
  fetchYesObjectsWithFilteraction,
  filteractionDescriptors
}: UsePrepareFilteractionQueriesProps) {
  const { sitemodelState } = useSitemodelState()

  const queries = useMemo(
    () =>
      Object.keys(filteractionDescriptors).flatMap((filteractionId) => {
        const descriptor = filteractionDescriptors[filteractionId].data

        if (!descriptor) {
          return []
        }

        const { interpolatedQuery, dependencies } = parseQuery(
          descriptor.query,
          sitemodelState
        )

        if (dependencies.some((dependency) => dependency === undefined)) {
          return []
        }

        const parsedFilteraction = {
          ...descriptor,
          query: interpolatedQuery
        }

        const queryFn = async () => {
          const { objects } = await fetchYesObjectsWithFilteraction({
            filteraction: parsedFilteraction,
            showLoading: false
          })

          return objects
        }

        const queryKey = [
          'filteraction-with-params',
          filteractionId,
          ...dependencies
        ]

        return {
          filteractionId,
          queryFn,
          queryKey
        }
      }),
    [filteractionDescriptors, sitemodelState, fetchYesObjectsWithFilteraction]
  )

  return queries
}

import {
  Button,
  DocumentListCard,
  SyntheticButton,
  YesObject
} from '@yes.technology/react-toolkit'
import useSelection from '../../hooks/useSelection'
import { useTranslation } from 'react-i18n-lite'
import { ButtonContainer, ButtonList, Container } from './TableActions.styles'
import useTableContext from '../../hooks/useTableContext'
import { TableActionsProps } from './TableActions.types'
import usePersistentSelection from 'site/Renderer/components/FilterFields/hooks/usePersistentSelection'
import useTableActionSave from '../../hooks/useTableActionSave/useTableActionSave'
import InsertButton from './InsertButton'

// hide select and deselect fields until implementation
const SHOW_SELECT_DESELECT = false

const TableActions = ({ initialStatus }: TableActionsProps) => {
  const { selectAllRows, selectedRows, deselectAllRows } = useSelection()
  const { t } = useTranslation()
  const { mode, setMode, setChangedFieldValues } = useTableContext()
  const { selectAndLink, shouldRenderSelectAndLink } = usePersistentSelection()

  const save = useTableActionSave({
    initialStatusUuid: initialStatus?.uuid || ''
  })

  //Temporary function
  const placeholderAction = () => undefined

  const getFormattedSelectedRows = () => {
    const formattedSelectedRows: YesObject[] = []

    Object.keys(selectedRows).forEach((row) => {
      const value = selectedRows[row].value

      value && formattedSelectedRows.push(value)
    })

    return formattedSelectedRows
  }

  const selectAndLinkAction = () => {
    const formattedSelectedRows = getFormattedSelectedRows()
    deselectAllRows()

    selectAndLink(formattedSelectedRows)
  }

  const cancel = () => {
    setMode('view')
    setChangedFieldValues({})
  }

  const selectionActionsOptions = [
    {
      children: t('data-tables.selection-actions'),
      onClick: placeholderAction,
      disabled: true,
      impactClass: 'situationChange' as const
    },
    {
      children: t('select-and-link.action'),
      onClick: selectAndLinkAction,
      disabled: !shouldRenderSelectAndLink(),
      impactClass: 'situationChange' as const
    }
  ]

  const buttonContainer =
    mode === 'edit' || mode === 'insert' ? (
      <div className='col-12 col-md-4'>
        <ButtonList>
          <Button
            onClick={save}
            variant='primary'
            impactClass='situationChange'
          >
            {t('data-tables.save-and-activate')}
          </Button>

          <Button onClick={cancel} variant='secondary'>
            {t('data-tables.cancel')}
          </Button>
        </ButtonList>
      </div>
    ) : (
      <ButtonContainer>
        <DocumentListCard>
          <ButtonList>
            {SHOW_SELECT_DESELECT && (
              <>
                <Button
                  heightClass='semiSmall'
                  onClick={placeholderAction}
                  variant='secondary'
                  disabled
                >
                  {t('data-tables.select')}
                </Button>
                <Button
                  heightClass='semiSmall'
                  onClick={placeholderAction}
                  variant='secondary'
                  disabled
                >
                  {t('data-tables.deselect')}
                </Button>
              </>
            )}
            <Button
              heightClass='semiSmall'
              onClick={selectAllRows}
              variant='secondary'
            >
              {t('data-tables.select-all')}
            </Button>
            <Button
              heightClass='semiSmall'
              onClick={deselectAllRows}
              variant='secondary'
              disabled={Object.keys(selectedRows).length === 0}
            >
              {t('data-tables.deselect-all')}
            </Button>
            <InsertButton />
            <Button
              heightClass='semiSmall'
              onClick={save}
              variant='secondary'
              impactClass='situationChange'
            >
              {t('data-tables.save-and-activate')}
            </Button>
            <Button
              heightClass='semiSmall'
              onClick={() => setMode('edit')}
              variant='secondary'
              impactClass='situationChange'
            >
              {t('data-tables.modify')}
            </Button>
            <SyntheticButton
              heightClass='semiSmall'
              options={selectionActionsOptions}
              dropdownLabel='more options'
            />
          </ButtonList>
        </DocumentListCard>
      </ButtonContainer>
    )

  return <Container>{buttonContainer}</Container>
}

export default TableActions

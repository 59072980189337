import { Col } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;

  gap: 24px;

  @media (min-width: 768px) {
    grid-template-columns: auto auto auto;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const StyledCol = styled(Col)`
  & > div {
    height: 100%;
  }
`

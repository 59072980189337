import { useMemo } from 'react'
import { useObjectclass } from 'objectclass/shared'
import useYesObject from 'yesObject/shared/hooks/useYesObject'

import getClassifiedFields from './getClassifiedFields'
import { Field } from '@yes.technology/react-toolkit'

type Props = {
  objectclassUuid: string
  classificationUuid?: string
}

const useObjectFields = ({ objectclassUuid, classificationUuid }: Props) => {
  const { objectclass, isLoading: isLoadingObjectclass } = useObjectclass({
    objectclassUuid
  })

  const classificationObject = useYesObject({
    objectUUID: classificationUuid || '',
    objectclassUUID: window.classificationObjectclassUuid
  })

  const allFields = useMemo(
    () =>
      Object.values(objectclass?.fields || {}).map((field) => {
        if (field?.type === 'RELATION') {
          const relationClasses = Object.values(
            objectclass?.relation_classes || {}
          )
          return {
            ...field,
            relation: relationClasses?.filter(
              (relation) => field.column_json === relation?.column_json
            )?.[0]
          }
        }

        return field
      }) as Field[],
    [objectclass]
  )

  const classifiedFields = useMemo(
    () =>
      getClassifiedFields(
        allFields,
        classificationObject?.yesObject?.classification_content as string
      ),
    [allFields, classificationObject?.yesObject?.classification_content]
  )

  const fields = useMemo(
    () =>
      classifiedFields.filter(
        (field) => field && field.type !== 'RELATION'
      ) as Field[],
    [classifiedFields]
  )

  const relationFields = useMemo(
    () =>
      classifiedFields.filter(
        (field): field is Field => field?.type === 'RELATION'
      ),
    [classifiedFields]
  )

  return {
    objectclass,
    fields,
    relationFields,
    isLoading: isLoadingObjectclass || classificationObject?.isLoading
  }
}

export default useObjectFields

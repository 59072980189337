import { Container, Image, Subtitle, Title } from './Unauthorized.styles'

export interface UnauthorizedProps {
  des?: string
  complement?: string
  imageSrc?: string
}

const Unauthorized = ({ des, complement, imageSrc }: UnauthorizedProps) => {
  return (
    <Container>
      {imageSrc && <Image src={imageSrc} alt='Unauthorized access' />}
      <Title>{des ?? 'Unauthorized'}</Title>
      {complement && <Subtitle>{complement}</Subtitle>}
    </Container>
  )
}

export default Unauthorized

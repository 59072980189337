import { v4 as uuidv4 } from 'uuid'
import fetch from 'cross-fetch'

class ApiAsync {
  setConfig(config) {
    this.config = config
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch
  }

  setAuthToken(authToken) {
    this.authToken = authToken
  }

  async fetchWithToken(params, requestUuid) {
    let urlApiMS = ''

    if (params.customEndpoint) {
      urlApiMS = params.endpoint
    } else {
      urlApiMS = this.config[params.endpoint]
    }

    if (params.path) {
      urlApiMS = urlApiMS + params.path
    }

    if (undefined === params.showLoading) {
      params.showLoading = true
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': this.getAcceptLanguage()
      // 'Accept-Encoding': 'gzip, compress, br'
    }

    if (this.authToken) {
      headers.AUTH_TOKEN = this.authToken
    }

    // temporário para utilizar um novo serviço de mock api
    if (params.secretKey) {
      headers['secret-key'] = params.secretKey
    }

    if (params.showLoading) {
      this.dispatch({
        type: 'api/REQUESTED',
        data: {
          requestUuid
        }
      })
    }

    return await fetch(urlApiMS, {
      method: params.method || 'GET',
      headers,
      body: JSON.stringify(params.obj)
    })
  }

  async requestSync(params) {
    const requestUuid = uuidv4()

    const response = await this.fetchWithToken(params, requestUuid)
    if (params.showLoading) {
      this.dispatch({
        type: 'api/RECEIVED',
        data: {
          requestUuid
        }
      })
    }

    if (response.status === 409) {
      return response
    }
    return await response.json()
  }

  setAcceptLanguage(lang) {
    this.lang = lang
  }

  getAcceptLanguage() {
    switch (this.lang) {
      case 'pt-BR':
        return 'pt-BR, en-US'
      case 'de':
        return 'de, en-US'
      default:
        return 'en-US'
    }
  }
}

export default new ApiAsync()

import styled from 'styled-components'

export const LayoutContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    width: calc(100vw - 64px);
  }

  @media (min-width: 768px) {
    justify-content: end;
    align-items: center;
    margin-bottom: 0;
  }
`

export const FileExportActions = styled.div`
  display: flex;
  gap: 8px;

  @media (min-width: 768px) {
    gap: 4px;
  }

  button,
  a {
    border: 3px solid transparent;
    padding: 0;
  }
`

export const LayoutAutocompleteWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;

  > :last-child {
    width: 100%;

    > * > * {
      background: #fff;
    }

    * {
      color: #303032;
    }
  }
`

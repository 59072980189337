import moment from 'moment'

import { CellValue } from '../types'
import { useTranslation } from 'react-i18n-lite'
import { YesObject, formatDateTime } from '@yes.technology/react-toolkit'

export type FieldType = 'DATETIME' | 'RELATION' | 'STRING' | 'UUID'

const formatArrayValue = (value: YesObject[]) =>
  value.map(({ des }) => des).join(', ')

const formatObjectValue = (value: object) => JSON.stringify(value)

export default function useFormattedValue({
  value,
  type = 'STRING'
}: {
  value: CellValue
  type?: FieldType
}): string | null {
  const { language } = useTranslation()

  if (!value) return null

  if (Array.isArray(value)) {
    return formatArrayValue(value)
  }

  if (typeof value === 'object') {
    return formatObjectValue(value)
  }

  if (type === 'DATETIME' && moment(value).isValid()) {
    return formatDateTime(
      new Date(value),
      language as Parameters<typeof formatDateTime>[1],
      'date-time'
    )
  }

  return String(value)
}

import {
  FilteractionObject,
  ProjectFields,
  YesObject
} from '@yes.technology/react-toolkit'
import useSitemodelState from '../useSitemodelState/useSitemodelState'
import { useQueries } from '@tanstack/react-query'
import { fetchYesObjectsWithFilteraction as defaultFetchYesObjectsWithFilteraction } from 'yesObject/shared/api/fetchYesObjectsWithFilteraction'
import { useEffect, useRef } from 'react'
import { SitemodelManifestStateGrouped } from 'sitemodel/shared/types/sitemodel.type'
import {
  filterArrayOfObjectValuesByKeys,
  filterArrayOfObjectsByKeys,
  filterObjectByKeys,
  filterObjectValuesByKeys
} from 'shared/utils'
import usePrepareFilteractionQueries from './usePrepareFilteractionQueries'
import { useSiteState } from 'siteState/shared'

type UseFilteractionQueriesProps = {
  filteractionDescriptors: Record<FilteractionID, { data?: FilteractionObject }>
  fetchYesObjectsWithFilteraction: typeof defaultFetchYesObjectsWithFilteraction
}

const useFilteractionQueries = ({
  fetchYesObjectsWithFilteraction,
  filteractionDescriptors
}: UseFilteractionQueriesProps) => {
  const queries = usePrepareFilteractionQueries({
    fetchYesObjectsWithFilteraction,
    filteractionDescriptors
  })

  return useQueries({
    queries,
    combine: (results) => {
      let allResultsFetched = true

      const resultsById = Object.fromEntries(
        results.map((result, index) => {
          allResultsFetched = allResultsFetched && result.isFetchedAfterMount

          return [queries[index].filteractionId, result]
        })
      )

      return {
        resultsById,
        allResultsFetched
      }
    }
  })
}

const transformFilteractionData = (
  data: YesObject[],
  type: SitemodelManifestStateGrouped['filteraction']['']['type'],
  projectFields: ProjectFields
) => {
  if (type === 'object[]') {
    return filterArrayOfObjectsByKeys(data, projectFields)
  }

  if (type === 'object') {
    return filterObjectByKeys(data[0], projectFields)
  }

  if (type === 'string[]') {
    return filterArrayOfObjectValuesByKeys(data, projectFields)
  }

  if (type === 'relation') {
    return filterObjectValuesByKeys(data[0], projectFields)
  }

  // string
  return filterObjectValuesByKeys(data[0], projectFields)
}

type FilteractionID = string

type UseSitemodelFilteractionParamsProps = {
  filteractionDescriptors: Record<FilteractionID, { data?: FilteractionObject }>
  filteractionManifestState: SitemodelManifestStateGrouped['filteraction']
  fetchYesObjectsWithFilteraction?: typeof defaultFetchYesObjectsWithFilteraction
}

export default function useSitemodelFilteractionParams({
  filteractionDescriptors,
  filteractionManifestState,
  fetchYesObjectsWithFilteraction = defaultFetchYesObjectsWithFilteraction
}: UseSitemodelFilteractionParamsProps) {
  const { mergeSitemodelState } = useSitemodelState()
  const [_, setAllResultsFetched] = useSiteState('allResultsFetched')

  const { allResultsFetched, resultsById } = useFilteractionQueries({
    filteractionDescriptors,
    fetchYesObjectsWithFilteraction
  })

  const lastAllResultsFetched = useRef(false)

  useEffect(() => {
    if (!allResultsFetched || lastAllResultsFetched.current) {
      lastAllResultsFetched.current = allResultsFetched
      return
    }
    lastAllResultsFetched.current = allResultsFetched

    const stateUpdateArray = Object.keys(filteractionManifestState).flatMap(
      (targetStateKey) => {
        const {
          filteraction: filteractionId,
          type: resultType,
          projectFields
        } = filteractionManifestState[targetStateKey]

        const result = resultsById[filteractionId]

        if (!result?.data) {
          return []
        }

        const transformedData = transformFilteractionData(
          result.data,
          resultType,
          projectFields
        )

        return [[targetStateKey, transformedData]]
      }
    )

    const stateUpdate = Object.fromEntries(stateUpdateArray)

    mergeSitemodelState(stateUpdate)
  }, [
    allResultsFetched,
    filteractionManifestState,
    mergeSitemodelState,
    resultsById
  ])

  useEffect(() => {
    setAllResultsFetched(allResultsFetched)
  }, [allResultsFetched, setAllResultsFetched])

  return { allResultsFetched }
}

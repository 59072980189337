import { useParams } from 'react-router-dom'
import { SitemodelUuid } from 'sitemodel/shared/types'
import { useFetchYesObjects } from 'yesObject/shared'
import useYesObject from 'yesObject/shared/hooks/useYesObject'

export default function useSitemodelFromUrl() {
  const { sitemodelUuid: sitemodelUuidInUrl } = useParams<SitemodelUuid>()

  const { yesObjects: homeSitemodels, isLoading: isLoadingHomeSitemodels } =
    useFetchYesObjects({
      filteractionUuid: window.defaultSitemodelFilteractionUuid,
      showLoading: true,
      initialSortOptions: [{ priority: 'desc' }],
      enabled: !sitemodelUuidInUrl
    })

  const { yesObject: sitemodelFromUrl, isLoading: isLoadingSitemodelFromUrl } =
    useYesObject({
      objectUUID: sitemodelUuidInUrl || '',
      objectclassUUID: window.idObjectclassSitemodel,
      showLoading: true
    })

  const sitemodel = sitemodelFromUrl || homeSitemodels?.[0]
  const isLoading = isLoadingSitemodelFromUrl || isLoadingHomeSitemodels

  return { sitemodel, isLoading }
}

import { Record, String } from 'runtypes'

export type DynamicTextHighlightContainerProps = {
  props: {
    baseText: string
    getState: string
  }
}

export const DynamicTextHighlightContainerTypeGuard = Record({
  baseText: String,
  getState: String
})

import jwtDecode from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'

import { loginSucceeded } from 'authentication/Login/ducks/authentication'
import {
  AuthenticationType,
  PublicSessionToken,
  Token
} from 'authentication/shared/types/authentication.type'
import apiAsync from 'infrastructure/shared/api/api-async'
import { ErrorMessage } from 'infrastructure/shared/types'

// https://github.com/auth0/jwt-decode/blob/master/index.d.ts
interface JwtHeader {
  typ?: string
  alg?: string
  kid?: string
}

interface Props {
  publicSessionToken: PublicSessionToken
}

const useToken = ({ publicSessionToken }: Props) => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const { token: tokenInState, tokenType: tokenTypeInState } = useSelector(
    (state: AuthenticationType) => state.authentication
  )

  const tokenInURL = searchParams.get('token')

  const token: Token =
    tokenInURL ?? tokenInState ?? publicSessionToken ?? undefined

  useEffect(() => {
    const validateAndSaveToken = () => {
      const tokenAlreadyUpdated = tokenInState && tokenInState === token

      if (!token || tokenAlreadyUpdated) return
      try {
        const decodedHeader = jwtDecode<JwtHeader>(token, { header: true })
        if (decodedHeader?.typ !== 'JWT' || decodedHeader?.alg !== 'HS256') {
          throw new Error('Invalid token')
        }
        apiAsync.setAuthToken(token)
        const decoded = jwtDecode<{ email: string; expiration_date: number }>(
          token
        )
        dispatch(
          loginSucceeded(decoded.email, {
            token,
            expiration_date: decoded.expiration_date,
            tokenType: tokenInURL ? 'private' : tokenTypeInState ?? 'public'
          })
        )
        searchParams.delete('token')
        setSearchParams(searchParams, { replace: true, state: location.state })
      } catch (error) {
        const err = error as ErrorMessage
        console.log(err.message)
      }
    }
    validateAndSaveToken()
  }, [
    dispatch,
    setSearchParams,
    searchParams,
    token,
    publicSessionToken,
    tokenInURL,
    tokenInState,
    tokenTypeInState,
    location
  ])

  return token
}

export default useToken

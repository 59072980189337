import { Icon, Text } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import styled from 'styled-components'

type States = 'no-data-to-view'

interface EmptyStatesProps {
  state: States
  showComplement?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const EmptyStates = ({ state, showComplement = true }: EmptyStatesProps) => {
  const { t } = useTranslation()

  if (state === 'no-data-to-view') {
    return (
      <Container>
        <Icon
          iconName='InputMissing'
          icSize='extraLarge'
          icColor='CESprimary'
        />
        <Text variant='content-emphasis-06'>
          {t('data.states.no-data-to-view.des')}
        </Text>
        {showComplement && (
          <Text variant='bordertext-04'>
            {t('data.states.no-data-to-view.complement')}
          </Text>
        )}
      </Container>
    )
  }

  return null
}

export default EmptyStates

import React from 'react'

import NavigationSliderTitle from '../NavigationSliderTitle/NavigationSliderTitle'
import { ActionsContainer, CollapsibleContainer, StyledGrid } from './styles'
import { Col, Row, useIsMobile } from '@yes.technology/react-toolkit'

type SectionDividerProps = {
  title?: string
  color?: string
  backgroundColor?: string
  children?: React.ReactNode
  centerHeaderElement?: React.ReactElement
  rightHeaderElement?: React.ReactElement
  embedded?: boolean
  disabled?: boolean
  isVisible?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const SectionDivider = ({
  title = '',
  children,
  centerHeaderElement,
  rightHeaderElement,
  backgroundColor,
  color,
  embedded,
  isVisible = true,
  ...htmlProps
}: SectionDividerProps) => {
  const isMobile = useIsMobile()

  const leftHeaderElement =
    isMobile && centerHeaderElement ? undefined : (
      <NavigationSliderTitle title={title} color={color} />
    )

  const actions = centerHeaderElement ? (
    <ActionsContainer>{centerHeaderElement}</ActionsContainer>
  ) : undefined

  if (!isVisible)
    return (
      <StyledGrid>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>{children}</Col>
        </Row>
      </StyledGrid>
    )

  return (
    <CollapsibleContainer
      headerColor='blue'
      heightClass='xSmall'
      leftHeaderElement={leftHeaderElement}
      centerHeaderElement={actions}
      rightHeaderElement={rightHeaderElement}
      alignSecondPosition='center'
      defaultOpenState
      backgroundColor={backgroundColor}
      color={color}
      embedded={embedded}
      {...htmlProps}
    >
      {children}
    </CollapsibleContainer>
  )
}

export default SectionDivider

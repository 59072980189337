import { YesObject } from '@yes.technology/react-toolkit'
import ContactObjectCard from 'component/ContactObjectCard'
import GenericObjectCard from 'component/GenericObjectCard'
import TaskObjectCard from 'component/TaskObjectCard'
import TransportOrderCard from 'component/TransportOrderCard'
import React from 'react'
import splitIntoChunks from 'shared/utils/array/splitIntoChunks'
import { CardModel, ColumnProps } from '../../Kanban.types'
import {
  CardContainer,
  ColumnCard,
  ColumnContainer,
  ColumnsContainer,
  KanbanColumnTitle
} from './KanbanAutoColumns.styles'

const validCardModels: {
  [key: string]: React.FC<YesObject>
} = {
  GenericObjectCard,
  TaskObjectCard,
  ContactObjectCard,
  TransportOrderCard
}

type KanbanAutoColumnsProps = {
  relationObjects: YesObject[]
  groupedYesObjects: Record<string, YesObject[]>
  cardModel: CardModel
  currentSection: number
  columnsPerPage: number
  sections: Record<string, YesObject[]>
  columnsOverrideAttributes: Record<string, ColumnProps>
}

function KanbanAutoColumns({
  relationObjects,
  groupedYesObjects,
  cardModel,
  currentSection,
  columnsPerPage,
  sections,
  columnsOverrideAttributes
}: KanbanAutoColumnsProps) {
  const CardComponent = validCardModels[cardModel]

  const currentSectionColumns = splitIntoChunks(
    Object.keys(sections),
    columnsPerPage
  )[currentSection]

  return (
    <ColumnsContainer>
      {currentSectionColumns.map((column, columnIndex) => {
        const columnRelationObjectIndex =
          currentSection * columnsPerPage + columnIndex

        const currentColumnObjects =
          groupedYesObjects[
            relationObjects[columnRelationObjectIndex].code as string
          ] || []

        const columnLabel =
          columnsOverrideAttributes?.[column]?.label ||
          relationObjects[columnRelationObjectIndex].des

        return (
          <ColumnContainer
            key={column}
            $basis={Math.round(100 / columnsPerPage)}
          >
            <ColumnCard fullHeight>
              <KanbanColumnTitle>{columnLabel}</KanbanColumnTitle>
              {currentColumnObjects.map((object) => (
                <CardContainer key={object.uuid}>
                  <CardComponent {...object} />
                </CardContainer>
              ))}
            </ColumnCard>
          </ColumnContainer>
        )
      })}
    </ColumnsContainer>
  )
}

export default KanbanAutoColumns

import {
  DocumentListCard,
  Field,
  YesObject
} from '@yes.technology/react-toolkit'
import RelationClass from '../RelationClass/RelationClass'
import { RelationConfigProps } from '../types/object-view'

type RelationClassesProps = {
  relationFields: Field[]
  fieldValues: YesObject | undefined
  relationConfig?: RelationConfigProps
  informational?: boolean
}

const RelationClasses = ({
  relationFields,
  fieldValues,
  relationConfig,
  informational
}: RelationClassesProps) => {
  return (
    <div className='row gap-4'>
      {relationFields?.map((relationField: Field, index) => {
        if (
          fieldValues?.[relationField.column_json] &&
          relationConfig?.[relationField.uuid]
        ) {
          return (
            <div
              key={`${relationField.relation_objectclass_uuid}-${index}`}
              className={`${relationConfig?.[relationField.uuid]?.column || 'col-md-6'}`}
            >
              <DocumentListCard style={{ padding: 0 }}>
                <RelationClass
                  relationFieldRelation={relationField.relation}
                  fieldValues={fieldValues}
                  hierarchyLevel={
                    relationConfig?.[relationField.uuid]?.hierarchy_level
                  }
                  actionButton={
                    relationConfig?.[relationField.uuid]?.action_button
                  }
                  layoutClassificationUuid={
                    relationConfig?.[relationField.uuid]
                      ?.uuid_layout_classification
                  }
                  field={relationField}
                  informational={informational}
                  navigationSliderBackgroundColor={
                    relationConfig?.[relationField.uuid]
                      ?.navigationslider_background_color
                  }
                  navigationSliderColor={
                    relationConfig?.[relationField.uuid]?.navigationslider_color
                  }
                />
              </DocumentListCard>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default RelationClasses

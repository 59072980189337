import { Record, String } from 'runtypes'

export type TextExpressionContainerProps = {
  props: {
    des: string
  }
}

export const TextExpressionContainerTypeGuard = Record({
  des: String
})

export const getBackgroundImage = (leftImg?: string, rightImg?: string) => {
  if (leftImg && rightImg)
    return `
    background-image: url(${leftImg}), url(${rightImg});
    background-position: left center, right center;
  `

  if (leftImg || rightImg)
    return `
    background-image: url(${leftImg || rightImg});
    background-position: ${leftImg ? 'left' : 'right'} center;
  `

  return ''
}

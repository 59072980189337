import { Dispatch } from 'redux'

export type TokenType = 'public' | 'private' | undefined

export type AuthData = {
  expiration_date: number
  token: string
  tokenType: TokenType
  isLogouting?: boolean
}

export type Response = { success: boolean; data?: AuthData }

export const loginSucceeded = (email: string, data: AuthData) => {
  return {
    type: 'login/SUCCEEDED',
    data: {
      username: email,
      expiration_date: data.expiration_date,
      token: data.token,
      tokenType: data.tokenType
    }
  }
}

export const loginFailed = () => {
  return {
    type: 'login/FAILED'
  }
}

export const systemReload = () => (dispatch: Dispatch<{ type: string }>) => {
  dispatch({ type: 'redux_store/CLEAR' })
}

/**
 * Reducer
 */
export default function authentication(
  state = {},
  action: { type: string; data?: AuthData }
) {
  switch (action.type) {
    case 'login/SUCCEEDED':
      return action.data
    case 'login/FAILED':
      return {}
    case 'login/TOKEN_EXPIRED':
      return {}
    case 'login/LOGOUT':
      return {
        isLogouting: true
      }
    default:
      return state
  }
}

import {
  Icon,
  InformationCard,
  useIsMobile
} from '@yes.technology/react-toolkit'
import { useIsOpen } from 'shared/hooks'

import { HeroBannerSecondaryProps } from './HeroBannerSecondary.types'
import {
  Container,
  Title,
  Subtitle,
  Content,
  StyledButton
} from './HeroBannerSecondary.styles'

const HeroBannerSecondary = ({
  des,
  complement,
  leftBackgroundImageUrl,
  rightBackgroundImageUrl
}: HeroBannerSecondaryProps) => {
  const isMobile = useIsMobile()
  const { isOpen, open, close } = useIsOpen(false)
  return (
    <>
      <Container {...{ leftBackgroundImageUrl, rightBackgroundImageUrl }}>
        <Content>
          <Title>{des}</Title>
          {isMobile && (
            <StyledButton onClick={open} variant='secondary'>
              <Icon iconName='Information' />
            </StyledButton>
          )}
          {!isMobile && <Subtitle>{complement}</Subtitle>}
        </Content>
      </Container>

      {isMobile && (
        <InformationCard title={des as string} isOpen={isOpen} close={close}>
          {complement}
        </InformationCard>
      )}
    </>
  )
}

export default HeroBannerSecondary

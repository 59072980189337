import { Optional, Record, String } from 'runtypes'

export type WorkflowmodelContainerProps = {
  props: {
    workflowmodelUuid?: string
    workflowUuid?: string
  }
}

export const WorkflowmodelContainerTypeGuard = Record({
  workflowmodelUuid: Optional(String),
  workflowUuid: Optional(String)
})

import { useCallback } from 'react'
import { isStatusObjectclass } from 'shared/utils/specialRelationFields/specialRelationFields'
import fetchYesObjects from 'yesObject/shared/api/fetchYesObjects'

type UseFetchSuggestions = {
  objectclassUuid: string
  fetchYesObjects: typeof fetchYesObjects
  uuidCurrentSitemodelObjectclass?: string
  skipRelations?: boolean
  skipMetadata?: boolean
}

export default function useFetchSuggestions({
  objectclassUuid,
  fetchYesObjects: fetchYesObjectsCallback,
  uuidCurrentSitemodelObjectclass,
  skipRelations = false,
  skipMetadata = false
}: UseFetchSuggestions) {
  const fetchSuggestions = useCallback(
    async (query: string) => {
      const isObjectclassStatus = isStatusObjectclass(objectclassUuid)
        ? {
            'objectclass.uuid': {
              $in: [uuidCurrentSitemodelObjectclass]
            }
          }
        : {}

      let filterQuery = {
        'situation.des': {
          $eq: '40'
        },
        'des.des': {
          $regex: `.*${query}.*`
        }
      }

      if (!filterQuery) {
        return Promise.resolve([])
      }

      filterQuery = { ...filterQuery, ...isObjectclassStatus }

      const yesResponse = await fetchYesObjectsCallback({
        filterBody: {
          query: {
            $and: [filterQuery]
          },
          objectclass: objectclassUuid,
          orderby: [
            {
              des: 'asc'
            }
          ],
          pagination: {
            offset: 0,
            limit: 100
          },
          skip_relations: skipRelations,
          skip_metadata: skipMetadata
        }
      })
      return yesResponse.objects || []
    },
    [
      objectclassUuid,
      uuidCurrentSitemodelObjectclass,
      fetchYesObjectsCallback,
      skipRelations,
      skipMetadata
    ]
  )

  return fetchSuggestions
}

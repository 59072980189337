import { DocumentListCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const CardContainer = styled.div`
  margin-top: 8px;
`

export const ColumnsContainer = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-grow: 1;
  padding: 0px 56px;
`

export const ColumnCard = styled(DocumentListCard)`
  background-color: #ffffff;
`

export const ColumnContainer = styled.div<{ $basis: number }>`
  flex-grow: 0;
  flex-basis: ${(props) => props.$basis}%;
`

export const KanbanColumnTitle = styled.h2`
  font-family: Barlow;
  color: #303032;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

import { useSelector } from 'react-redux'
import {
  LoadingPage,
  FeedbackCardContainer
} from '@yes.technology/react-toolkit'

import 'bootstrap/dist/css/bootstrap.min.css'

import AuthenticationRenewal from 'authentication/shared/components/AuthenticationRenewal'
import ServiceWorkerWrapper from 'infrastructure/ServiceWorkerWrapper'
import EnvironmentWarning from 'infrastructure/Application/components/EnvironmentWarning'

import { Router as ApplicationRouter } from './components'
import { useToken, useHandleLanguage } from 'shared/hooks'

import 'shared/assets/css/fonts.css'
import 'shared/assets/css/default.css'
import { SiteStateContext, useSiteStateProvider } from 'siteState/shared'
import { Font } from '@react-pdf/renderer'

export type ApiSystemType = { api: { isPending: boolean } }

Font.register({
  family: 'Barlow',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf'
    },
    {
      src: 'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gc4FAtlT47dw.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E30-8c4FAtlT47dw.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4c4FAtlT47dw.ttf',
      fontWeight: 700
    }
  ]
})

const ApplicationContainer: React.FunctionComponent = (): JSX.Element => {
  const isLoading = useSelector((state: ApiSystemType) => state.api?.isPending)

  useToken({ publicSessionToken: window.publicSessionToken })
  useHandleLanguage()

  const siteStateProviderValue = useSiteStateProvider()

  return (
    <SiteStateContext.Provider value={siteStateProviderValue}>
      {isLoading && (
        <div className='loading-page'>
          <LoadingPage />
        </div>
      )}
      <ServiceWorkerWrapper />
      <EnvironmentWarning />
      <AuthenticationRenewal />
      <ApplicationRouter />
      <FeedbackCardContainer pauseOnFocusLoss={false} pauseOnHover={false} />
    </SiteStateContext.Provider>
  )
}

export default ApplicationContainer

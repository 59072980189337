import { useEffect, useMemo, useState } from 'react'

import { EventCardContainerProps } from './EventCardContainer.types'
import NavigationSlider from '../CardCarousel/NavigationSlider/NavigationSlider'
import { useSiteState } from 'siteState/shared'
import {
  Col,
  FilterQuery,
  Row,
  SkeletonDots,
  Text,
  YesObject
} from '@yes.technology/react-toolkit'
import { useFetchYesObjects } from 'yesObject/shared'
import { useTranslation } from 'react-i18n-lite'
import EventCard from '../EventCard/EventCard'
import { StyledGrid } from 'shared/style/grid'
import { useObjectclass } from 'objectclass/shared'
import { merge } from 'lodash'
import { StyledCol } from './EventCardContainer.styles'

const EventCardContainer = ({ props }: EventCardContainerProps) => {
  const { language, t } = useTranslation()

  const [page, setPage] = useState(0)

  const {
    uuid_objectclass: objectclassUuid,
    merge_filter_query: mergeFilterQuery
  } = props

  const { objectclass, isLoading: isLoadingObjectClass } = useObjectclass({
    objectclassUuid
  })

  const [filterQuery] = useSiteState<FilterQuery>('filter-query')

  const finalFilterQuery = useMemo(() => {
    if (filterQuery && mergeFilterQuery) {
      return { ...merge(filterQuery, mergeFilterQuery) }
    }
    return filterQuery
  }, [filterQuery, mergeFilterQuery])

  const {
    yesObjects,
    isFetching: isLoadingObjects,
    fetchYesObjects
  } = useFetchYesObjects({
    filterBody: {
      query: finalFilterQuery,
      objectclass: objectclassUuid
    },
    enabled: !!finalFilterQuery
  })

  useEffect(() => {
    if (finalFilterQuery) fetchYesObjects()
  }, [finalFilterQuery, fetchYesObjects])

  const pages = useMemo(() => {
    const mappedObjects: Record<string, YesObject[]> = {}

    yesObjects?.forEach((object) => {
      if (!object.start_date) {
        return
      }

      const startDate = new Date(object.start_date as string)
      const year = startDate.getFullYear()
      const month = startDate.getMonth()
      const dateKey = `${month}/${year}`

      if (Array.isArray(mappedObjects[dateKey])) {
        mappedObjects[dateKey].push(object)
      } else {
        mappedObjects[dateKey] = [object]
      }
    })

    const pages = Object.keys(mappedObjects)
      .map((key) => {
        const [month, year] = key.split('/')
        const date = new Date(Number(year), Number(month)).toLocaleDateString(
          'en-US'
        )

        return {
          date,
          objects: mappedObjects[key]
        }
      })

      .sort((firstObject, secondObject) => {
        const firstDate = new Date(firstObject.date)
        const secondDate = new Date(secondObject.date)
        if (firstDate > secondDate) {
          return 1
        }
        if (firstDate < secondDate) {
          return -1
        }
        return 0
      })

    return pages
  }, [yesObjects])

  const handlePrevClick = () => {
    setPage((prevPage) => (prevPage === 0 ? prevPage : prevPage - 1))
  }

  const handleNextClick = () => {
    setPage((prevPage) =>
      prevPage === pages.length - 1 ? prevPage : prevPage + 1
    )
  }

  const title = useMemo(() => {
    const currentPage = pages[page]

    if (!currentPage) {
      return ''
    }

    const date = new Date(currentPage.date)
    const month = date.toLocaleString(language, { month: 'long' })
    const year = date.getFullYear()

    return `${month.charAt(0).toUpperCase()}${month.slice(1)}/${year}`
  }, [page, pages, language])

  if (isLoadingObjectClass || isLoadingObjects) {
    return (
      <StyledGrid>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <SkeletonDots />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  if (yesObjects.length === 0) return <div style={{ marginTop: '16px' }} />

  const fields = objectclass?.fields

  return (
    <>
      <NavigationSlider
        title={title}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        disabled={pages.length <= 1}
        currentIndex={page}
      />

      <StyledGrid>
        <Row style={{ justifyContent: 'center' }}>
          {pages[page]?.objects.map((object) => {
            const organization = (object.organization?.[0] as YesObject)?.des
            const creator = (object.organizer_organization?.[0] as YesObject)
              ?.des
            const establishment = (object.establishment?.[0] as YesObject)?.des

            const startDate = new Date(
              object.start_date as string
            ).toLocaleString(language)
            const endDate = new Date(object.end_date as string).toLocaleString(
              language
            )

            return (
              <StyledCol
                key={object.uuid}
                lg={4}
                style={{ marginBottom: '16px' }}
              >
                <EventCard
                  des={object.des}
                  startDate={`${t('event-card.start-date')}: ${startDate}`}
                  endDate={`${t('event-card.end-date')}: ${endDate}`}
                  documents={object.document as YesObject[]}
                >
                  <>
                    {organization && (
                      <div>
                        <Text variant='content-03' as='div'>
                          {`${t('event-card.organization')}: ${organization}`}
                        </Text>

                        <Text variant='content-03' as='div'>
                          {`${t('event-card.creator')}: ${creator}`}
                        </Text>
                      </div>
                    )}

                    {establishment && (
                      <div>
                        <Text variant='content-emphasis-03' as='div'>
                          {t('event-card.establishment')}:
                        </Text>

                        <Text variant='content-03' as='div'>
                          {establishment as string}
                        </Text>
                      </div>
                    )}

                    {object.complement && (
                      <div>
                        <Text variant='content-emphasis-03' as='div'>
                          {t('event-card.des')}:
                        </Text>

                        <Text variant='content-03' as='div'>
                          {object.complement}
                        </Text>
                      </div>
                    )}

                    {object.obs && (
                      <div>
                        <Text variant='content-emphasis-03' as='div'>
                          {t('event-card.obs')}:
                        </Text>

                        <Text variant='content-03' as='div'>
                          {object.obs as string}
                        </Text>
                      </div>
                    )}
                  </>
                </EventCard>
              </StyledCol>
            )
          })}
        </Row>
      </StyledGrid>
    </>
  )
}

export default EventCardContainer

import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import jwtDecode from 'jwt-decode'

import { AuthenticationType } from 'authentication/shared/types'
import Iframe from 'shared/components/Iframe'
import useIframe from 'site/Render/hooks/useIframe'
import Avatar from '../Avatar/Avatar'
import { getInitials } from 'shared/utils/string/userInitials'

import {
  ActiveSessionCard,
  ActiveSessionCardContainer,
  LoginButtonContainer,
  LoginContainer
} from './styles'

export function shouldProcessMessage(
  eventOrigin: string,
  expectedOrigin: string
): boolean {
  return eventOrigin === expectedOrigin.replace(/\/$/g, '')
}

const Login: React.FunctionComponent = (): JSX.Element | null => {
  const { setLanguage, language, t } = useTranslation()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const outerRef = useRef<HTMLDivElement>(null)
  const btnRef = useRef<HTMLButtonElement>(null)

  const { token, tokenType } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  const encodeHref = encodeURIComponent(window.location.href)
  const redirectOrigin = `redirectOrigin=${encodeHref}`
  const waAuthenticationUrl = window.waAuthenticationUrl
  const encodeToken = encodeURIComponent(token)
  const encodeLanguage = encodeURIComponent(language)

  const iframeSrc = `${waAuthenticationUrl}active-session?token=${encodeToken}&lang=${encodeLanguage}`

  const { iframeAttributes, receiveMessage } = useIframe({
    origin: waAuthenticationUrl,
    src: iframeSrc,
    title: 'Active Session',
    initialHeight: '300px'
  })

  const handleActiveSession = () => {
    setIsOpen(!isOpen)
  }

  const handleLogout = useCallback(() => {
    dispatch({
      type: 'login/LOGOUT'
    })
    setIsOpen(false)
  }, [dispatch])

  const handleLogin = () => {
    if (window?.managementEnvironmentSitemodel) {
      window.location.assign(`/${window.managementEnvironmentSitemodel}`)
    } else {
      window.location.assign(`${waAuthenticationUrl}login?${redirectOrigin}`)
    }
  }

  const handleModifyCredentials = useCallback(() => {
    window.location.assign(
      `${waAuthenticationUrl}credentials?${redirectOrigin}&token=${encodeURIComponent(token)}`
    )
  }, [waAuthenticationUrl, redirectOrigin, token])

  const onReceivedMessage = useCallback(
    (event: MessageEvent) => {
      receiveMessage(event)

      if (!shouldProcessMessage(event.origin, waAuthenticationUrl)) return

      if (event.data.logout) {
        handleLogout()
      }

      if (event.data.modifyCredentials) {
        handleModifyCredentials()
      }

      if (event.data.language) {
        setLanguage(event.data.language)
      }
    },
    [
      receiveMessage,
      waAuthenticationUrl,
      handleLogout,
      handleModifyCredentials,
      setLanguage
    ]
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (btnRef.current && btnRef.current.contains(event.target as Node)) {
        return
      }

      if (
        outerRef.current &&
        !outerRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        setIsOpen(false)
      }
    },
    [outerRef, btnRef, isOpen]
  )

  useEffect(() => {
    window.addEventListener('message', onReceivedMessage)
    return () => window.removeEventListener('message', onReceivedMessage)
  }, [onReceivedMessage])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => window.removeEventListener('click', handleClickOutside, true)
  }, [handleClickOutside])

  // user initials
  const decoded =
    (token &&
      jwtDecode<{
        name?: string
        username?: string
        email?: string
      }>(token)) ||
    {}

  const user = decoded?.name || decoded?.username || decoded?.email

  return (
    <LoginContainer>
      {token && tokenType === 'private' && (
        <>
          <Avatar
            initials={getInitials(user || 'Yes')}
            title={user || undefined}
            onClick={handleActiveSession}
            ref={btnRef}
          />
        </>
      )}

      {(!token || tokenType === 'public') && (
        <LoginButtonContainer>
          <Button heightClass='semiSmall' onClick={handleLogin}>
            {t('login.button.des')}
          </Button>
        </LoginButtonContainer>
      )}

      {token && (
        <ActiveSessionCardContainer
          ref={outerRef}
          {...{ isOpen }}
          data-testid='active-session-card'
        >
          <ActiveSessionCard>
            {iframeAttributes && <Iframe {...iframeAttributes} />}
          </ActiveSessionCard>
        </ActiveSessionCardContainer>
      )}
    </LoginContainer>
  )
}

export default Login

import { Record, String, Array, Number } from 'runtypes'

type OptionType = {
  value: string
  color: string
}

export type NavigationSliderSecondaryContainerProps = {
  props: {
    options: OptionType[]
    currentIndex: number
  }
}

const OptionTypeGuard = Record({
  value: String,
  color: String
})

export const NavigationSliderSecondaryContainerTypeGuard = Record({
  options: Array(OptionTypeGuard),
  currentIndex: Number
})

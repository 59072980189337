import {
  FilterBody,
  FilteractionObject,
  Pagination,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { resolveQuery } from 'filteraction/FilteractionResolver'
import interpolateFilteractionQuery from '../utils/interpolateQuery'

type FetchFilteractionFilterbodyProps = {
  filteraction: FilteractionObject
  pagination?: Pagination
  orderby?: SortOption[]
  showLoading?: boolean
  onProgressCallback?: (message: string) => void
  interpolationObject?: Record<string, any>
}

export const DEFAULT_PAGINATION = { limit: 100, offset: 0 }

export const DEFAULT_ORDER_BY: SortOption[] = [
  {
    date_creation_row: 'desc'
  }
]

export default async function fetchFilteractionFilterbody({
  filteraction,
  pagination = DEFAULT_PAGINATION,
  orderby = DEFAULT_ORDER_BY,
  showLoading = false,
  onProgressCallback = console.log,
  interpolationObject
}: FetchFilteractionFilterbodyProps): Promise<FilterBody> {
  const interpolatedQuery = interpolateFilteractionQuery(
    filteraction,
    interpolationObject
  )

  const filteractionQuery = JSON.parse(interpolatedQuery)

  const filteractionObjectclassUuid = (
    filteraction.filter_action_objectclass[0] as YesObject | undefined
  )?.uuid

  const filteractionResolvedQuery = await resolveQuery(
    filteractionQuery,
    onProgressCallback,
    [filteraction.uuid],
    showLoading
  )

  const query = {
    $and: [filteractionResolvedQuery]
  }

  const filterBody = {
    query,
    objectclass: filteractionObjectclassUuid || '',
    pagination,
    orderby
  }

  return filterBody
}

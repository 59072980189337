import { useMemo } from 'react'
import LoadComponent from 'site/Renderer/LoadComponent'
import { Component } from 'site/shared/types'

export type AreaProps = {
  props?: { components: Component[] } | null
}

const Area = ({ props }: AreaProps) => {
  const components = useMemo(() => props?.components, [props?.components])
  if (!components?.length) return null
  return (
    <>
      <LoadComponent components={components} />
    </>
  )
}

export default Area

import { DocumentListCard, Field } from '@yes.technology/react-toolkit'
import { useEffect, useState } from 'react'

import { formatFields } from '../../shared'
import Fields from '../Fields/Fields'
import { FieldValues, FilterAreaLayout, GroupedFieldValues } from '../../types'
import { FilterActionSetup, FilterButton } from '../../FilterFields.styles'
import FilteractionSelection from '../FilteractionSelection/FilteractionSelection'
import useObjectClassFilteractions from 'filteraction/shared/hooks/useObjectClassFilteractions'
import { useSituations } from 'situation/shared'
import getOnSelectFilteraction from '../FilteractionSelection/getOnSelectFilteraction'
import GroupControls from '../GroupControls/GroupControls'
import { GridContainer } from 'shared/style/grid'
import getFilterStateFromFilteraction from '../FilteractionSelection/getQueryDataFromFilteraction'
import SectionDivider from 'site/Renderer/components/SectionDivider'
import { useSiteState } from 'siteState/shared'
import { FilterFieldsDisplayOptions } from '../../FilterFields'

type FieldsProps = {
  filterAreaLayout?: FilterAreaLayout
  sections: Field[][]
  groupedFieldValues: GroupedFieldValues
  initialFieldValues: FieldValues
  composition: string
  title?: string
  handleChange?: (value: GroupedFieldValues) => void
  handleClear?: () => void
  handleComposition: (composition: string) => void
  uuidCurrentSitemodelObjectclass?: string
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  defaultExecutedFilteraction?: string
  executeFilter: (fieldValues: GroupedFieldValues, composition: string) => void
}

const Section = ({
  filterAreaLayout,
  composition,
  sections,
  initialFieldValues,
  groupedFieldValues,
  title,
  handleChange,
  handleClear,
  handleComposition,
  uuidCurrentSitemodelObjectclass = '',
  navigationSliderBackgroundColor,
  navigationSliderColor,
  defaultExecutedFilteraction,
  executeFilter
}: FieldsProps) => {
  // const { t } = useTranslation()

  const [sectionPosition, setSectionPosition] = useState<number>(1)
  const sectionsLength = sections.length

  const currentFields = formatFields(sections[sectionPosition - 1])

  // const [filterPosition, setFilterPosition] = useState(0)
  // const filtersLength = 1
  // const changeFilter = (position: number) => setFilterPosition(position - 1)

  const [groupPosition, setGroupPosition] = useState(1)
  const groupsLength = Object.keys(groupedFieldValues).length

  if (groupsLength < groupPosition) {
    setGroupPosition(1)
  }

  const [filteractionValue, setFilteractionValue] = useState('')

  const filteractions = useObjectClassFilteractions({
    objectclassUuid: uuidCurrentSitemodelObjectclass
  })

  const situations = useSituations()

  const onSelectFilteraction = getOnSelectFilteraction({
    initialFieldValues,
    sections,
    situations,
    handleChange,
    handleClear,
    handleComposition
  })

  const fieldValues = groupedFieldValues[groupPosition]

  const handleChangeFieldValues = (value: FieldValues) => {
    handleChange?.({ [groupPosition]: value })
  }

  const handleClearCurrentGroup = () =>
    handleChangeFieldValues(initialFieldValues)

  const handleCreateGroup = () => {
    handleChange?.({
      [groupsLength + 1]: initialFieldValues
    })
    setGroupPosition((currentGroup) => currentGroup + 1)
  }

  const handleClearAll = () => {
    handleClear?.()
    setFilteractionValue('')
  }

  useEffect(() => {
    if (
      defaultExecutedFilteraction &&
      filteractions.length > 0 &&
      situations.length > 0
    ) {
      const filteraction = filteractions.find(
        (filteraction) => filteraction.uuid === defaultExecutedFilteraction
      )

      if (filteraction) {
        const { queryData, composition } = getFilterStateFromFilteraction({
          initialFieldValues,
          sections,
          situations,
          filteraction
        })

        executeFilter(queryData, composition)
        setFilteractionValue(filteraction.des)
        handleChange?.(queryData)
        handleComposition(composition)
      }
    }
  }, [
    defaultExecutedFilteraction,
    filteractions,
    situations,
    executeFilter,
    sections,
    initialFieldValues,
    handleComposition,
    handleChange
  ])

  const hideFiltersAfterFilteraction =
    !!filterAreaLayout?.filteractions?.hide_filters_after_filteraction
  const shouldHideFilters = hideFiltersAfterFilteraction && !!filteractionValue

  const sectionLayout = filterAreaLayout?.filter_sections?.[sectionPosition - 1]

  const [displayOptions] = useSiteState<FilterFieldsDisplayOptions>(
    'filter-display-options'
  )

  return (
    <SectionDivider
      title={`${title} | Filtros`}
      backgroundColor='#004099'
      isVisible={!displayOptions?.hideMainCollapsibleContainer}
    >
      <DocumentListCard style={{ padding: 8 }}>
        <FilterActionSetup>
          <FilterButton
            type='submit'
            className='flex-grow-1 flex-md-grow-0 fw-semibold'
            heightClass='regular'
          >
            Filtrar
          </FilterButton>
          <FilterButton
            variant='secondary'
            onClick={handleClearAll}
            className='flex-grow-1 flex-md-grow-0 fw-semibold'
            heightClass='regular'
          >
            Limpar
          </FilterButton>
          {!displayOptions?.hideFilteraction && (
            <FilteractionSelection
              value={filteractionValue}
              setValue={setFilteractionValue}
              {...{ filteractions, onSelectFilteraction }}
            />
          )}
          {/* <div
            className='d-md-grid d-flex flex-column gap-2'
            style={{ gridAutoFlow: 'column' }}
          >
            <Unifree
              label='Composição da Ação de Filtragem'
              value={composition.replace(/\b(AND|OR)\b/gi, (match) =>
                match.toUpperCase() === 'AND'
                  ? t('composition-operator.and')
                  : t('composition-operator.or')
              )}
              informational
            />
          </div>
          <CompositionButtons
            {...{ composition, handleComposition, handleCreateGroup }}
          />
          <AutocompleteMultiselection
            options={[]}
            value=''
            label='Layout'
            required
            disabled
          /> */}
        </FilterActionSetup>
        <GroupControls
          {...{
            sectionPosition,
            sectionsLength,
            groupPosition,
            groupsLength,
            setGroupPosition,
            setSectionPosition,
            handleClearCurrentGroup,
            navigationSliderBackgroundColor,
            navigationSliderColor,
            shouldHideFilters
          }}
        >
          <div className='px-3'>
            {currentFields.length && !shouldHideFilters && (
              <Fields
                sectionLayout={sectionLayout}
                fields={currentFields}
                handleChange={handleChangeFieldValues}
                {...{
                  fieldValues,
                  uuidCurrentSitemodelObjectclass,
                  groupedFieldValues
                }}
              />
            )}
          </div>
        </GroupControls>
      </DocumentListCard>
    </SectionDivider>
  )
}

export default Section

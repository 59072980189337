import useSituationWithActions from '../../hooks/useSituationWithActions'
import {
  DocumentListCard,
  SkeletonDots,
  SyntheticButton,
  Unifree,
  YesObject
} from '@yes.technology/react-toolkit'
import patchObject from 'yesObject/shared/api/patchObject'

interface ShowSituationProps {
  situationCode?: string
  objectClassUUID: string
  objectVersion: string
  objectUUID: string
  mainActionLabel: string
  mainActionOnClick: () => void
  onRefresh?: () => void
  yesObject: YesObject
  informational?: boolean
}

function ShowSituation({
  yesObject,
  situationCode,
  objectClassUUID,
  objectVersion,
  objectUUID,
  mainActionLabel,
  mainActionOnClick,
  onRefresh,
  informational
}: ShowSituationProps) {
  const { isLoading, objectSituation, visibleSituationActions } =
    useSituationWithActions({ situationCode })

  const onActionClick = (destinationCode: string) => {
    patchObject({
      uuid: objectUUID,
      data: {
        uuid_Objectclass: objectClassUUID,
        situation: destinationCode,
        version: objectVersion
      },
      allowSpecialFields: true
    }).then(() => {
      onRefresh?.()
    })
  }

  const options = [
    { children: mainActionLabel, onClick: mainActionOnClick },
    ...visibleSituationActions.flatMap((action) =>
      action.destination_situation.length > 0
        ? {
            children: action.des,
            onClick: () => onActionClick(action.destination_situation[0].code)
          }
        : []
    )
  ]

  return (
    <div className='col-md-6'>
      <DocumentListCard className='p-2'>
        {isLoading && <SkeletonDots />}

        {!isLoading && (
          <div className='row align-items-end g-1'>
            {objectSituation && (
              <div
                className={`${informational ? 'col-md-12' : 'col-12 col-xl-9'}`}
              >
                <Unifree
                  label='SID | Situação | Versão'
                  value={`${yesObject.sid} | ${objectSituation.des} | ${objectVersion}`}
                  disabled
                  required
                  readOnly={informational}
                />
              </div>
            )}

            {!informational && (
              <div className='col-12 col-xl-3'>
                <SyntheticButton
                  {...{ options }}
                  dropdownLabel='Ver situations'
                  variant='primary'
                  heightClass='regular'
                />
              </div>
            )}
          </div>
        )}
      </DocumentListCard>
    </div>
  )
}

export default ShowSituation

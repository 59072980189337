import { createContext, useCallback, useContext } from 'react'

type Updater<T> = (prevState: T) => T
type SetStateAction<T> = T | Updater<T>

type SiteStateContextType = {
  items: { [key: string]: any }
  setSiteStateItem: <T>(key: string, value: SetStateAction<T>) => void
}

export const SiteStateContext = createContext<SiteStateContextType | undefined>(
  undefined
)

export default function useSiteState<ItemType>(
  stateItemKey: string
): [ItemType, (value: SetStateAction<ItemType>) => void] {
  const context = useContext(SiteStateContext)

  if (context === undefined) {
    throw new Error('useSiteState must be used within a SiteStateProvider')
  }

  const { items, setSiteStateItem } = context
  const item = items[stateItemKey] as ItemType

  const setItem = useCallback(
    (setStateAction: SetStateAction<ItemType>) => {
      setSiteStateItem(stateItemKey, setStateAction)
    },
    [stateItemKey, setSiteStateItem]
  )

  return [item, setItem]
}

import { Record, String, Array } from 'runtypes'

type AccordionItem = {
  uuid: string
  des: string
  complement: string
}

export type AccordionProps = {
  props: {
    items: AccordionItem[]
  }
}

const AccordionItemGuard = Record({
  uuid: String,
  des: String,
  complement: String
})

export const AccordionTypeGuard = Record({
  items: Array(AccordionItemGuard)
})

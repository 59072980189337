import { YesObject } from '@yes.technology/react-toolkit'
import { DirectionsButton } from './DirectionsButton'
import {
  Address,
  CardContent,
  Content,
  Grupoalvo,
  ImageContainer,
  Title,
  WorkingSchedule
} from './MapContainer.styles'
import { MarkerProps } from './MapContainer.types'
import { useGeolocation } from 'shared/hooks'
import ImageDocument from 'component/ImageDocument/ImageDocument'

export const DeliveryPointInfoWindow = ({ object }: MarkerProps) => {
  const grupoalvo = getName(object?.gruposalvos as YesObject[])
  const addressObj = formatAddress(object.address?.[0] as YesObject)
  const cityName = getName(object?.cidade as YesObject[])
  const stateName = getName(object?.estado as YesObject[])

  const { currentPosition } = useGeolocation()

  const logoCpec =
    'https://s3.amazonaws.com/yes.network/static-files/cpec-logo.svg'

  const workingSchedule = object.workingschedule as YesObject[]

  return (
    <CardContent>
      <ImageContainer>
        <ImageDocument documents={object.documento || logoCpec} height='70' />
      </ImageContainer>
      <Content>
        <Title>{object.des}</Title>
        {grupoalvo && <Grupoalvo>{grupoalvo}</Grupoalvo>}
        <Address>
          {formatFullAddress(
            {
              des: addressObj.des,
              number: addressObj?.number as string,
              complement: addressObj.complement,
              neighborhood: addressObj.neighborhood as string,
              zipCode: addressObj.zipCode as string
            },
            cityName,
            stateName
          )}
        </Address>
        {workingSchedule && (
          <WorkingSchedule>
            {workingSchedule?.map((schedule: YesObject) => (
              <div key={schedule.uuid}>{schedule.des}</div>
            ))}
          </WorkingSchedule>
        )}
        {currentPosition && (
          <DirectionsButton
            origin={currentPosition}
            destination={{
              lat: addressObj.latitude,
              lng: addressObj.longitude
            }}
          />
        )}
      </Content>
    </CardContent>
  )
}

const getName = (obj: YesObject[] | undefined) => {
  return typeof obj === 'object' ? obj[0].des : ''
}

const formatAddress = (address: YesObject) => {
  if (!address)
    return {
      des: '',
      number: '',
      complement: '',
      neighborhood: '',
      zipCode: '',
      latitude: 0,
      longitude: 0
    }
  return {
    des: address.des,
    number: address.number,
    complement: address.complement,
    neighborhood: address.neighborhood,
    zipCode: address.zip_code,
    latitude: parseFloat(address.latitude as string),
    longitude: parseFloat(address.longitude as string)
  }
}

interface AddressObj {
  des: string
  number?: string
  complement?: string
  neighborhood?: string
  zipCode?: string
}

const formatFullAddress = (
  addressObj: AddressObj,
  cityName: string,
  stateName: string
) => (
  <>
    {addressObj?.des}
    {addressObj?.number ? `, ${addressObj?.number}` : ''}
    {addressObj?.complement ? ` - ${addressObj?.complement}` : ''}
    {addressObj?.neighborhood ? ` - ${addressObj?.neighborhood}` : ''}
    {cityName && stateName ? `, ${cityName} - ${stateName}` : ''}
    {addressObj?.zipCode ? `, ${addressObj?.zipCode}` : ''}
  </>
)

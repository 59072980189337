import { HierarchyCard } from '@yes.technology/react-toolkit'
import { Link } from 'react-router-dom'
import { BreakpointRange } from 'shared/style/grid'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: 0 1rem;

  @media (min-width: ${BreakpointRange.lg.min}px) {
    padding: 0 1.5rem;
  }

  @media (min-width: ${BreakpointRange.xl.min}px) {
    padding: 0 2rem;
  }

  nav {
    padding: 0;
  }
`

export const StyledLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`

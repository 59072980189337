import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { TextExpressionContainerProps } from './TextExpressionContainer.types'
import { StyledSkeletonDots, StyledText } from './TextExpressionContainer.style'
import { useSiteState } from 'siteState/shared'
import { SkeletonDots } from '@yes.technology/react-toolkit'

const TextExpressionContainer = ({ props }: TextExpressionContainerProps) => {
  const [allResultsFetched] = useSiteState('allResultsFetched')
  const isLoading = !allResultsFetched
  return (
    <>
      {!isLoading && (
        <StyledText>{<SafeHtmlContent html={props.des} />}</StyledText>
      )}
      {isLoading && (
        <StyledSkeletonDots>
          <SkeletonDots />
        </StyledSkeletonDots>
      )}
    </>
  )
}

export default TextExpressionContainer

import { Container, StyledAreaLine } from './NavigationSlider.styles'
import NavigationSliderSecondary from 'component/NavigationSliderSecondary'

type NavigationSliderProps = {
  title: string
  onPrevClick: () => void
  onNextClick: () => void
  disabled?: boolean
  currentIndex: number
}

const NavigationSlider = ({
  title,
  onPrevClick,
  onNextClick,
  disabled,
  currentIndex
}: NavigationSliderProps) => {
  const option = { value: title, color: '#303032' }
  const options = Array.from({ length: currentIndex + 1 }, () => option)

  const centerAreaElement = (
    <Container>
      <NavigationSliderSecondary
        options={options}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        currentIndex={currentIndex}
        disabled={disabled}
      />
    </Container>
  )

  return <StyledAreaLine centerAreaElement={centerAreaElement} />
}

export default NavigationSlider

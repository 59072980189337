import HeroBanner from './HeroBanner/HeroBanner'
import { HeroBannerContainerProps } from './HeroBannerContainer.types'
import { StyledContainer } from './styles'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

const HeroBannerContainer = ({ props }: HeroBannerContainerProps) => {
  return (
    <StyledContainer>
      <HeroBanner
        {...props}
        des={props.des ? <SafeHtmlContent html={props.des} /> : ''}
        complement={
          props.complement ? <SafeHtmlContent html={props.complement} /> : ''
        }
        desColor={props.desColor || '#ffffff'}
        complementColor={props.complementColor || '#ffffff'}
        backgroundPositionDesktop='center'
        backgroundPositionMobile='center'
      />
    </StyledContainer>
  )
}

export default HeroBannerContainer

import SupportBannerSecondary from 'component/SupportBannerSecondary'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { SupportBannerSecondaryContainerProps } from './SupportBannerSecondaryContainer.types'

const SupportBannerSecondaryContainer = ({
  props
}: SupportBannerSecondaryContainerProps) => {
  return (
    <SupportBannerSecondary
      {...props}
      des={props.des && <SafeHtmlContent html={props.des} />}
      complement={
        props.complement && <SafeHtmlContent html={props.complement} />
      }
    />
  )
}

export default SupportBannerSecondaryContainer

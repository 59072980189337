import { Record, String, Optional } from 'runtypes'

export type HeroBannerSecondaryContainerProps = {
  props: {
    des: string
    complement: string
    leftBackgroundImageUrl?: string
    rightBackgroundImageUrl?: string
    anchorIdentifier?: string
  }
}

export const HeroBannerSecondaryContainerTypeGuard = Record({
  des: String,
  complement: String,
  leftBackgroundImageUrl: Optional(String),
  rightBackgroundImageUrl: Optional(String),
  anchorIdentifier: Optional(String)
})

import { useTranslation } from 'react-i18n-lite'
import {
  ArrowsUpAndDown,
  AscendingSortArrow,
  DescendingSortArrow
} from '../SVG'

import useTableContext from '../hooks/useTableContext'
import { SortOption } from '@yes.technology/react-toolkit'
import { DataTableSkeletonDots, TableHeaderColumn } from '../DataTable.styles'

type TableHeaderProps = {
  isLoading: boolean
}

function TableHeader({ isLoading }: TableHeaderProps) {
  const { t } = useTranslation()

  const {
    mode,
    fields,
    actionColumns,
    actionColumnsPosition,
    columnsNames,
    columnsLayouts,
    sortOptions,
    onSort,
    informational,
    actionButton,
    embedded,
    hideRowActionButtons
  } = useTableContext()

  const contentCells = columnsNames.map((columnName) => {
    const sortOptionsObject = sortOptions?.find(
      (sortOption) => !!sortOption[columnName]
    )
    const currentClassification = sortOptionsObject?.[columnName] || ''

    const nextClassification = currentClassification === 'asc' ? 'desc' : 'asc'

    const newSortOptions: SortOption[] = [{ [columnName]: nextClassification }]
    const updateSortDirection = () => {
      onSort(newSortOptions)
    }

    const columnLayout = columnsLayouts.find(
      (layout) => layout.name === columnName
    )
    const columnWidth = columnLayout?.width || ''

    const thProps = {
      style: { width: columnWidth }
    }

    if (isLoading) {
      return (
        <TableHeaderColumn key={columnName} {...thProps}>
          <DataTableSkeletonDots />
        </TableHeaderColumn>
      )
    }

    return (
      <TableHeaderColumn key={columnName} {...thProps}>
        {mode === 'view' && fields?.[columnName]?.type !== 'RELATION' && (
          <button
            role='button'
            className={`${
              sortOptionsObject ? 'sort-icon-active' : 'sort-icon'
            }`}
            style={{ backgroundColor: 'transparent', border: 0 }}
            onClick={updateSortDirection}
          >
            {currentClassification === 'asc' && <AscendingSortArrow />}
            {currentClassification === 'desc' && <DescendingSortArrow />}
            {currentClassification === '' && <ArrowsUpAndDown />}
          </button>
        )}
        {fields?.[columnName]?.display_text.des}
      </TableHeaderColumn>
    )
  })

  const showColumn =
    !embedded || actionButton?.type === 'document' || !informational

  let actionCells = actionColumns.map(
    (column) =>
      showColumn && (
        <TableHeaderColumn
          style={{ width: '5%' }}
          className='text-center'
          key={column.title}
        >
          {' '}
          {isLoading ? (
            <DataTableSkeletonDots />
          ) : (
            t(`data-table.${column.title}`)
          )}{' '}
        </TableHeaderColumn>
      )
  )

  const selectCell = !informational && mode === 'view' && (
    <TableHeaderColumn style={{ width: '5%' }} key='select-cell'>
      {isLoading ? <DataTableSkeletonDots /> : t(`data-tables.select`)}
    </TableHeaderColumn>
  )

  if (!fields) {
    return null
  }

  actionCells = hideRowActionButtons ? [] : actionCells

  return (
    <thead>
      <tr>
        {actionColumnsPosition === 'first'
          ? [selectCell, ...actionCells, ...contentCells]
          : [selectCell, ...contentCells, ...actionCells]}
      </tr>
    </thead>
  )
}

export default TableHeader

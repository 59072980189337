import { LinkButton, Text, useIsMobile } from '@yes.technology/react-toolkit'

import {
  Container,
  Description,
  ImageWrapper,
  StyledCard
} from './PartnerCard.styles'
import ImageDocument from 'component/ImageDocument/ImageDocument'
import { createQueryString } from 'shared/utils'
import { PartnerCardProps } from 'site/Renderer/components/PartnerCardContainer/PartnerCardContainer.types'

const PartnerCard = ({ item, button }: PartnerCardProps) => {
  const isMobile = useIsMobile()

  const buttonConfig = button?.[item.uuid]

  return (
    <StyledCard spacingClass='medium'>
      <Container>
        <ImageWrapper>
          <ImageDocument documents={item.documento} />
        </ImageWrapper>
        <Text
          variant={isMobile ? 'content-emphasis-04' : 'content-emphasis-06'}
          as='h3'
        >
          {item.des}
        </Text>
        <Description variant='label-01'>{item.complement}</Description>

        {buttonConfig && buttonConfig.type === 'link' && (
          <LinkButton
            href={buttonConfig.link.url}
            target={buttonConfig.link.target ?? '_self'}
            heightClass='small'
          >
            {buttonConfig.label}
          </LinkButton>
        )}
      </Container>
    </StyledCard>
  )
}

export default PartnerCard

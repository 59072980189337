import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { TranslationContainer } from 'react-i18n-lite'
import { ThemeProvider } from 'styled-components'
import {
  defaultTheme,
  ErrorBoundary,
  YesErrorTracking
} from '@yes.technology/react-toolkit'
import { HelmetProvider } from 'react-helmet-async'

import store, { persistor } from 'state/store'
import apiAsync from 'infrastructure/shared/api/api-async'
import validateConfigUrls from 'infrastructure/shared/utils/validateConfigUrls'
import ApplicationContainer from 'infrastructure/Application/Container'
import ResizeDetector from 'shared/components/ResizeDetector'
import { tagManager } from 'shared/utils'

import reportWebVitals from './reportWebVitals'

import locales from './locales'
import GenericErrorPage from 'infrastructure/GenericErrorPage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

validateConfigUrls(window.apiUrls)
apiAsync.setConfig(window.apiUrls)
apiAsync.setDispatch(store.dispatch)

YesErrorTracking({
  service: 'sentry',
  publicKey: window.sentryDNS,
  traceTargets: window.trackingUrls
})

tagManager.initialize(window.tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function renderError() {
  return <GenericErrorPage />
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ResizeDetector>
                <TranslationContainer locales={locales} defaultLanguage='pt-BR'>
                  <HelmetProvider>
                    <ErrorBoundary renderOnError={renderError}>
                      <ApplicationContainer />
                    </ErrorBoundary>
                  </HelmetProvider>
                </TranslationContainer>
              </ResizeDetector>
            </BrowserRouter>
          </PersistGate>
        </QueryClientProvider>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

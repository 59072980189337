import { HierarchyCard } from '@yes.technology/react-toolkit'
import { GoogleChartWrapperChartType } from 'react-google-charts'

import { GridContainer } from 'shared/style/grid'
import styled from 'styled-components'
import { CustomChart } from './Cockpit.types'

export const ExpandedButton = styled.button`
  color: #006bff;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
`
export const StyledChartWrapper = styled.div<{ $expanded: boolean }>`
  ${({ $expanded }) =>
    $expanded &&
    `
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
  `}
`

export const StyledGridContainer = styled(GridContainer)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  background-color: rgb(0, 64, 153);

  h1 {
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }
`

export const DataOrigin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`

export const FilterDate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
  }
`

export const StyledHierarchyCard = styled(HierarchyCard)`
  > :first-child {
    border-style: dashed;
  }
`

export const StyledGoogleChart = styled.div<{
  chartModel: GoogleChartWrapperChartType | CustomChart
}>`
  h2 {
    z-index: 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin: 0;
  }

  ${({ chartModel }) =>
    chartModel === 'GeoChart'
      ? `
  svg path[fill^='none'] {
    stroke-width: 0px;
  }
  `
      : ``}
`

export const StyledBrazilChart = styled.div<{
  chartModel: GoogleChartWrapperChartType
}>`
  h2 {
    z-index: 1;
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin: 0;
  }
`

export const Text = styled.text``

import { useEffect, useState } from 'react'

import {
  FilterQuery,
  NavigationSlider,
  Text,
  YesObject
} from '@yes.technology/react-toolkit'
import useFilteraction from 'filteraction/shared/hooks/useFilteraction'
import { useObjectclass } from 'objectclass/shared'
import { useTranslation } from 'react-i18n-lite'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'
import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'
import Pagination from '../Pagination'
import {
  KanbanMainContainer,
  NavigationContainer,
  SIZE_MAP
} from './Kanban.styles'
import { KanbanCardsProps } from './Kanban.types'
import KanbanAutoColumns from './components/KanbanAutoColumns'
import { filterSectionsByLayout } from './helpers'
import useRelationObjects from './hooks/useRelationObjects'

function Kanban({ props }: KanbanCardsProps) {
  const {
    uuid_objectclass: siteModelObjectclassUuid,
    uuid_filteraction: filteractionUuid,
    card_model: cardModel,
    group_by: groupByField,
    layout
  } = props

  const { t } = useTranslation()

  const filteraction = useFilteraction({ filteractionUuid: filteractionUuid })

  const objectclassUuid = filteractionUuid
    ? (filteraction?.filter_action_objectclass[0] as YesObject)?.uuid
    : siteModelObjectclassUuid

  const { objectclass, isLoading: isLoadingObjectClass } = useObjectclass({
    objectclassUuid
  })

  const [filterQuery] = useSiteState<FilterQuery>('filter-query')

  const {
    yesObjects,
    isLoading: isLoadingObjects,
    pagination,
    fetchYesObjects,
    totalObjects,
    setPage
  } = useFetchYesObjects({
    initialSortOptions: layout?.order_by,
    initialPagination: { limit: 20, offset: 0 },
    filterBody: {
      query: filterQuery,
      objectclass: objectclassUuid,
      skip_relations: false
    },
    filteractionUuid,
    enabled: !filteractionUuid
  })

  const { relationObjects, isLoading: isLoadingFieldValues } =
    useRelationObjects({
      field_column: groupByField,
      objectclass: objectclass,
      objectclass_uuid: objectclassUuid
    })

  const breakpoint = useBreakpoint()
  const pageColumns = SIZE_MAP[cardModel][breakpoint]
  const [currentSection, setCurrentSection] = useState(0)

  const isLoading =
    isLoadingObjects || isLoadingObjectClass || isLoadingFieldValues

  useEffect(() => {
    if (filterQuery || filteractionUuid) fetchYesObjects()
    // ???: Não funciona se n tiver o filteraction aqui em dep, não sei pq
  }, [filterQuery, filteractionUuid, fetchYesObjects, filteraction, pagination])

  useEffect(() => {
    // !!!: Breakpoint só pra evitar bugs com a mudança do tamanho
    setCurrentSection(0)
  }, [breakpoint, relationObjects])

  const groupedYesObjects = relationObjects.reduce(
    (acc, relationObject) => {
      return {
        ...acc,
        [relationObject.code as string]: yesObjects.filter(
          (yesObject) => yesObject[groupByField] === relationObject.code
        )
      }
    },
    {} as Record<string, YesObject[]>
  )

  const { filteredSections, columnsOverrideAttributes } =
    filterSectionsByLayout(groupedYesObjects, layout?.sections)

  const totalSections = Object.keys(filteredSections).length

  return (
    <KanbanMainContainer>
      <NavigationContainer>
        <Text variant='label-required-02'>{objectclass?.des || ''}</Text>

        <div style={{ justifySelf: 'center' }}>
          <NavigationSlider
            currentPosition={currentSection + 1}
            first={1}
            total={Math.ceil(totalSections / pageColumns)}
            textForCurrent={t('kanban.section')}
            afterClick={(n) => setCurrentSection(n - 1)}
            doubleArrowhead={false}
            hierarchyLevel='level_2'
            heightClass='xSmall'
            singleArrowIconName='NavigationRightOutline'
          />
        </div>

        <div style={{ justifySelf: 'flex-end' }}>
          <Pagination
            pagination={pagination}
            totalItems={totalObjects}
            setCurrentPage={setPage}
            hierarchyLevel='level_1'
            navigationSliderBackgroundColor='#004099'
            currentValueEditable
            showTotalPages
          />
        </div>
      </NavigationContainer>

      {!isLoading && relationObjects.length > 0 && groupedYesObjects && (
        <KanbanAutoColumns
          cardModel={cardModel}
          relationObjects={relationObjects}
          groupedYesObjects={groupedYesObjects}
          currentSection={currentSection}
          columnsPerPage={pageColumns}
          sections={filteredSections}
          columnsOverrideAttributes={columnsOverrideAttributes}
        />
      )}
    </KanbanMainContainer>
  )
}

export default Kanban

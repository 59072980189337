import { YesObject } from '@yes.technology/react-toolkit'
import { Literal, Optional, Record, String, Union, Array } from 'runtypes'

export type PositionType = 'left' | 'right'

export type ArticleButton = {
  label: string
  href: string
}

export type ArticleContainerProps = {
  props: {
    imageUrl: string | YesObject[]
    des: string
    position: PositionType
    complement: string
    imageAlt?: string
    buttons?: ArticleButton[]
  }
}

const Position = Union(Literal('left'), Literal('right'))

export const ArticleContainerTypeGuard = Record({
  des: String,
  complement: String,
  imageUrl: String,
  position: Position,
  imageAlt: Optional(String),
  buttons: Optional(Array(Record({ label: String, href: String })))
})

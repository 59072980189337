import { AreaLine } from '@yes.technology/react-toolkit'

import { TopAreaLayoutType, TopAreaPositionType } from 'site/shared/types'
import Position from './Position'
import { TopLinePropsType } from './TopLine.types'
import { StyledContainer } from './styles'

export type TopLineProps = {
  props: TopLinePropsType
}

export const renderArea = (
  position?: TopAreaPositionType[],
  isCenter?: boolean
) => (
  <div className={`d-flex gap-3 ${isCenter ? 'breadcrumb' : ''}`}>
    {position &&
      position.map((component, index) => (
        <Position key={index} {...component} />
      ))}
  </div>
)

export const parseLayout = (
  layout?: TopAreaLayoutType | string
): TopAreaLayoutType | undefined => {
  let parsedData

  if (typeof layout === 'string') {
    try {
      parsedData = JSON.parse(layout) as TopAreaLayoutType
    } catch (error) {
      parsedData = {}
    }
  } else {
    parsedData = layout
  }

  return parsedData
}

const TopLine = ({ props }: TopLineProps) => {
  const layout = parseLayout(props?.layout)

  if (!layout) return null

  return (
    <StyledContainer>
      <AreaLine
        className='navbar-expand-lg'
        leftAreaElement={renderArea(layout.position_1)}
        centerAreaElement={renderArea(layout.position_2, true)}
        rightAreaElement={renderArea(layout.position_3)}
        alignment='left'
        colorScheme='white'
        heightClass='medium'
      />
    </StyledContainer>
  )
}

export default TopLine

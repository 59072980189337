import { YesObject } from '@yes.technology/react-toolkit'
import ArticleContainer from '../ArticleContainer'
import { ArticleButton } from '../ArticleContainer/ArticleContainer.types'

type ArticlesListContainerProps = {
  props: {
    items?: YesObject[]
    button_source_map?: { [uuid: string]: ArticleButton[] }
  }
}

const ArticlesListContainer = ({ props }: ArticlesListContainerProps) => {
  const { items, button_source_map: buttonSourceMap } = props
  return (
    <div>
      {items?.map((item: YesObject, index: number) => {
        return (
          <ArticleContainer
            key={item.uuid}
            props={{
              position: `${index % 2 === 0 ? 'right' : 'left'}`,
              des: item.des,
              complement: item.complement,
              imageUrl: item.documento,
              buttons: buttonSourceMap?.[item.uuid]
            }}
          />
        )
      })}
    </div>
  )
}

export default ArticlesListContainer

import { Record, String } from 'runtypes'

export type AuthenticationProps = {
  props: {
    redirectOrigin: string
  }
}

export const AuthenticationTypeGuard = Record({
  redirectOrigin: String
})

import { useId } from 'react'
import useTableContext from '../../hooks/useTableContext'
import { AutocompleteUniselection, Icon } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import {
  LayoutAutocompleteWrapper,
  FileExportActions,
  LayoutContainer
} from './LayoutAndReport.styles'

export default function LayoutAndReport() {
  const { mode, triggerReportDownload, reportUrl, displayLayoutSelection } =
    useTableContext()

  const { t } = useTranslation()

  const layoutSelectionLabelId = useId()

  const pdfDownloadClasses = 'btn btn-link p-0'

  if (
    mode !== 'view' ||
    (!triggerReportDownload && !reportUrl && !displayLayoutSelection)
  ) {
    return null
  }

  return (
    <LayoutContainer>
      <FileExportActions>
        {triggerReportDownload && !reportUrl && (
          <button
            type='button'
            className={pdfDownloadClasses}
            onClick={triggerReportDownload}
            title={t('data-tables.download-report')}
          >
            <Icon iconName='PDFFile' icSize='large' icColor='sanJuan' />
          </button>
        )}
        {reportUrl && (
          <a
            className={pdfDownloadClasses}
            href={reportUrl}
            download='report.pdf'
            title={t('data-tables.download-report')}
          >
            <Icon iconName='PDFFile' icSize='large' icColor='sanJuan' />
          </a>
        )}
      </FileExportActions>

      {displayLayoutSelection && (
        <LayoutAutocompleteWrapper>
          <div className='navigation-slider-title' id={layoutSelectionLabelId}>
            Layout
          </div>

          <AutocompleteUniselection
            aria-labelledby={layoutSelectionLabelId}
            options={[]}
            heightClass='semiSmall'
            disabled
            value=''
          />
        </LayoutAutocompleteWrapper>
      )}
    </LayoutContainer>
  )
}

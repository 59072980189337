import { Record, String, Array, Optional } from 'runtypes'

type Links = {
  label: string
  url: string
}

export type SupportBannerSecondaryContainerProps = {
  props: {
    des: string
    complement: string
    links: Links[]
  }
}

const LinksTypeGuard = Record({
  label: String,
  url: String
})

export const SupportBannerSecondaryContainerTypeGuard = Record({
  des: String,
  complement: Optional(String),
  links: Optional(Array(LinksTypeGuard))
})

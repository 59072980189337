import {
  DataTable as ToolkitDataTable,
  FilterQuery
} from '@yes.technology/react-toolkit'

import { useObjectclass } from 'objectclass/shared'
import { useMemo } from 'react'
import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'

type FilterFieldsProps = {
  objectclassUuid?: string
  consumedStateKey?: string
}

function DataTable({
  objectclassUuid = '',
  consumedStateKey = ''
}: FilterFieldsProps) {
  const { objectclass } = useObjectclass({ objectclassUuid })

  const { fields = {} } = objectclass || {}

  const columns = useMemo(() => Object.keys(fields), [fields])

  const [filterQuery] = useSiteState<FilterQuery>(consumedStateKey)

  const {
    yesObjects,
    isLoading,
    pagination,
    totalObjects,
    setPage,
    sortOptions,
    setSortOptions
  } = useFetchYesObjects({
    filterBody: { query: filterQuery, objectclass: objectclassUuid }
  })

  return (
    <ToolkitDataTable
      columns={columns}
      fields={fields}
      isLoading={isLoading}
      idObjectClass={objectclassUuid}
      objects={yesObjects}
      onSort={setSortOptions}
      setCurrentPage={setPage}
      actionColumns={[]}
      actionColumnsPosition='last'
      columnsLayouts={[]}
      pagination={pagination}
      totalObjects={totalObjects}
      sortOptions={sortOptions}
      // TODO: Handle checkboxes state
      onChangeCheckboxes={console.log}
      selectedValues={[]}
    />
  )
}

export default DataTable

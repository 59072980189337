import { useCallback, useEffect, useMemo, useRef } from 'react'
import chroma from 'chroma-js'

import { StyledBrazilChart } from '../Cockpit.styles'
import { ReactComponent as BrazilMapSvg } from '../assets/br.svg'
import {
  ChartWrapperOptions,
  GoogleChartWrapperChartType
} from 'react-google-charts'
import { DataChart } from './ChartWrapper'

const statesByRegion = {
  'Centro-Oeste': ['BR-DF', 'BR-GO', 'BR-MT', 'BR-MS'],
  Nordeste: [
    'BR-AL',
    'BR-BA',
    'BR-CE',
    'BR-MA',
    'BR-PB',
    'BR-PE',
    'BR-PI',
    'BR-RN',
    'BR-SE'
  ],
  Norte: ['BR-AC', 'BR-AP', 'BR-AM', 'BR-PA', 'BR-RO', 'BR-RR', 'BR-TO'],
  Sudeste: ['BR-ES', 'BR-MG', 'BR-RJ', 'BR-SP'],
  Sul: ['BR-PR', 'BR-RS', 'BR-SC']
}

interface BrazilMapProps {
  chartModel: GoogleChartWrapperChartType
  data: DataChart
  options: ChartWrapperOptions['options']
  title: string
}

interface ApplyColorsProps {
  coloredData: {
    region: string
    value: string | number | null
    color: string
  }[]
  defaultColor: string
  isRegionMap: boolean
  isStateMap: boolean
  svgElement: React.RefObject<SVGSVGElement>
}

interface SetLabelsProps {
  labelSelector: string
  data: DataChart
  svgElement: React.RefObject<SVGSVGElement>
}

export function applyColors({
  coloredData,
  defaultColor,
  isRegionMap,
  isStateMap,
  svgElement
}: ApplyColorsProps) {
  if (!svgElement.current) return

  const paths = svgElement.current.querySelectorAll('path')

  paths.forEach((path) => {
    const stateId = path.getAttribute('id')

    let backgroundColor = defaultColor
    const strokeColor = '#ccc'

    if (isStateMap) {
      backgroundColor =
        coloredData.find((p) => p.region === stateId)?.color || defaultColor
    }

    if (isRegionMap && stateId) {
      const region = Object.entries(statesByRegion).find(([, states]) =>
        states.includes(stateId)
      )?.[0]

      backgroundColor =
        coloredData.find((p) => p.region === region)?.color || defaultColor
    }

    path.setAttribute('fill', backgroundColor)
    path.setAttribute('stroke', strokeColor)
  })
}

export function setLabels({ labelSelector, data, svgElement }: SetLabelsProps) {
  if (!svgElement.current) return

  const circles = svgElement.current.querySelectorAll(labelSelector + ' circle')

  circles.forEach((circle) => {
    const id = circle.getAttribute('id') || ''
    const label = data.find((p) => p[0] === id)?.[1]

    const isLabel = label !== undefined && label !== null && label !== ''

    if (isLabel) {
      const cx = circle.getAttribute('cx') || '0'
      const cy = circle.getAttribute('cy') || '0'

      const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
      )
      text.setAttribute('x', cx)
      text.setAttribute('y', cy)
      text.setAttribute('font-size', '1.5rem')
      text.setAttribute('text-anchor', 'middle')
      text.setAttribute('fill', 'black')
      text.setAttribute('stroke', 'none')

      const tspanName = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )
      tspanName.textContent = id.replace('BR-', '')
      tspanName.setAttribute('x', cx)
      tspanName.setAttribute('dy', '0')

      const tspanLabel = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )
      tspanLabel.textContent = label.toString()
      tspanLabel.setAttribute('x', cx)
      tspanLabel.setAttribute('dy', '1em')
      tspanLabel.setAttribute(
        'style',
        '-webkit-tap-highlight-color: rgba(0, 0, 0, 0);'
      )

      text.appendChild(tspanName)
      text.appendChild(tspanLabel)

      svgElement.current?.appendChild(text)
    }
  })
}

function BrazilMap({ chartModel, data, options, title }: BrazilMapProps) {
  const svgRef = useRef<SVGSVGElement>(null)

  const { displayMode, defaultColor, colorAxis } = options || {}

  const isRegionMap = displayMode === 'regions'
  const isStateMap = displayMode === 'states'

  const coloredData = useMemo(() => {
    const values = data.slice(1).map((item) => Number(item[1]))
    const minValue = Math.min(...values)
    const maxValue = Math.max(...values)
    const colorScale = chroma
      .scale(colorAxis.colors)
      .domain([minValue, maxValue])
    return data.slice(1).map(([region, value]) => ({
      region,
      value,
      color: value ? colorScale(Number(value)).hex() : defaultColor
    }))
  }, [colorAxis.colors, data, defaultColor])

  const updateRegionChart = useCallback(() => {
    if (!isRegionMap) return
    applyColors({
      coloredData,
      defaultColor,
      isRegionMap,
      isStateMap,
      svgElement: svgRef
    })
    setLabels({
      labelSelector: '#regions_label',
      data,
      svgElement: svgRef
    })
  }, [isRegionMap, coloredData, defaultColor, isStateMap, data])

  const updateStateChart = useCallback(() => {
    if (!isStateMap) return
    applyColors({
      coloredData,
      defaultColor,
      isRegionMap,
      isStateMap,
      svgElement: svgRef
    })
    setLabels({
      labelSelector: '#states_label',
      data,
      svgElement: svgRef
    })
  }, [isStateMap, coloredData, defaultColor, isRegionMap, data])

  useEffect(updateRegionChart, [updateRegionChart])
  useEffect(updateStateChart, [updateStateChart])

  return (
    <StyledBrazilChart {...{ chartModel }}>
      <h2>{title}</h2>
      <BrazilMapSvg width={'100%'} ref={svgRef} />
    </StyledBrazilChart>
  )
}

export default BrazilMap

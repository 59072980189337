import { PartnerCard } from 'component/PartnerCard'
import { GridContainer } from 'shared/style/grid'
import { PartnerCardContainerProps } from './PartnerCardContainer.types'
import { StyledPartnerCardContainer } from './PartnerCardContainer.styles'

const PartnerCardContainer = ({ props }: PartnerCardContainerProps) => {
  const { objects, button } = props

  return (
    <StyledPartnerCardContainer>
      <GridContainer>
        <div className='row justify-content-center'>
          {objects?.map((object) => (
            <div key={object.uuid} className='col-xl-3 mb-3 mb-sm-4'>
              <PartnerCard key={object.uuid} item={object} button={button} />
            </div>
          ))}
        </div>
      </GridContainer>
    </StyledPartnerCardContainer>
  )
}

export default PartnerCardContainer

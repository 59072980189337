import { Icon } from '@yes.technology/react-toolkit'

import { SiteFooterContainerProps } from './SiteFooterContainer.types'
import {
  Address,
  Container,
  Copyright,
  GoToTopButton,
  GoToTopContainer,
  LogoContainer,
  LogoImage,
  NavigationList,
  NavigationListItem,
  SystemMessage
} from './SiteFooterContainer.styles'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

const SiteFooterContainer = ({ props }: SiteFooterContainerProps) => {
  const {
    ownerLinks,
    systemLinks,
    logoUrl,
    address,
    systemMessage,
    copyright
  } = props

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <Container>
      {ownerLinks && (
        <NavigationList>
          {ownerLinks?.map(({ link, label }, index) => (
            <NavigationListItem key={`${index}-ownerLinks`}>
              <a href={link} target='_blank' rel='noreferrer'>
                {label}
              </a>
            </NavigationListItem>
          ))}
        </NavigationList>
      )}

      {logoUrl && (
        <LogoContainer>
          <LogoImage src={logoUrl} alt='Logo do Sistema de Aplicação' />
        </LogoContainer>
      )}

      <GoToTopContainer data-testid='go-to-top'>
        <GoToTopButton onClick={goToTop}>
          <Icon iconName='ArrowUp' icColor='white' />
        </GoToTopButton>
      </GoToTopContainer>

      {systemMessage && (
        <SystemMessage>
          <SafeHtmlContent html={systemMessage} />
        </SystemMessage>
      )}

      {systemLinks && (
        <NavigationList>
          {systemLinks?.map(({ link, label }, index) => (
            <NavigationListItem key={`${index}-systemLinks`}>
              <a href={link} target='_blank' rel='noreferrer'>
                {label}
              </a>
            </NavigationListItem>
          ))}
        </NavigationList>
      )}

      {address && (
        <Address>
          <SafeHtmlContent html={address} />
        </Address>
      )}

      {copyright && (
        <Copyright>
          <SafeHtmlContent html={copyright} />
        </Copyright>
      )}
    </Container>
  )
}

export default SiteFooterContainer

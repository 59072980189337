import { useCallback, useState } from 'react'
import {
  Button,
  Col,
  DotNavigation,
  LinkButton,
  Row,
  SkeletonDots
} from '@yes.technology/react-toolkit'

import {
  Content,
  DetailsSectionContainer,
  Image,
  Navigation,
  StyledHierarchyCard,
  Summary,
  Title
} from './SummaryDetailsCarouselContainer.styles'
import { SummaryDetailsCarouselContainerProps } from './SummaryDetailsCarouselContainer.types'
import ImageDocument from 'component/ImageDocument/ImageDocument'
import { useIsOpen } from 'shared/hooks'
import DetailsSection from 'component/DetailsSection'
import { generateSummary } from 'shared/utils/string/summary'
import { StyledGrid } from 'shared/style/grid'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import EmptyStates from 'component/EmptyStates/EmptyStates'
import { useSiteState } from 'siteState/shared'

const SummaryDetailsCarouselContainer = ({
  props
}: SummaryDetailsCarouselContainerProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { isOpen, open, close } = useIsOpen(false)

  const { items, button, summary } = props

  const currentItem = items?.[selectedIndex] ?? {}

  const onNavigate = useCallback((index: number) => {
    setSelectedIndex(index)
  }, [])

  const onReadMore = useCallback(() => {
    open()
  }, [open])

  const isObjectNotEmpty = Object.keys(currentItem).length > 0

  const [allResultsFetched] = useSiteState('allResultsFetched')
  const isLoading = !allResultsFetched

  if (isLoading) {
    return (
      <StyledGrid>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <SkeletonDots />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  if (!isObjectNotEmpty) {
    return (
      <StyledGrid>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg={12}>
            <EmptyStates state='no-data-to-view' showComplement={false} />
          </Col>
        </Row>
      </StyledGrid>
    )
  }

  return (
    <StyledGrid>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={12}>
          <StyledHierarchyCard>
            {currentItem.des && <Title>{currentItem.des}</Title>}
            <Content>
              {currentItem.documento && (
                <Image>
                  <ImageDocument documents={currentItem.documento} />
                </Image>
              )}
              <Summary>
                {currentItem.complement &&
                  (summary ? (
                    generateSummary(currentItem.complement, summary?.max_length)
                  ) : (
                    <SafeHtmlContent html={currentItem.complement} />
                  ))}

                {button && button.type === 'modal' && (
                  <Button onClick={onReadMore}>{button.label}</Button>
                )}

                {button && button.type === 'link' && (
                  <LinkButton
                    href={button.link.url}
                    target={button.link.target ?? '_self'}
                  >
                    {button.label}
                  </LinkButton>
                )}
              </Summary>
            </Content>
          </StyledHierarchyCard>

          {items.length > 1 && (
            <Navigation>
              <DotNavigation
                length={items.length}
                onSelect={onNavigate}
                selected={selectedIndex}
                disabled={items.length <= 1}
              />
            </Navigation>
          )}

          {isOpen && (
            <DetailsSectionContainer>
              <DetailsSection
                isOpen={isOpen}
                onClose={close}
                des={currentItem.des}
                complement={currentItem.complement}
              />
            </DetailsSectionContainer>
          )}
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default SummaryDetailsCarouselContainer

export type IframeAttributes = {
  src: string
  height?: string
  title?: string
  allow?: string
}
const Iframe = (props: IframeAttributes | undefined): JSX.Element => {
  const style: React.CSSProperties = {
    border: 'none',
    display: 'block',
    width: '100%',
    padding: 0,
    margin: 0,
    flex: 1
  }

  return <iframe {...props} style={style} />
}

export default Iframe

import { useMemo } from 'react'

import {
  NavigationSlider,
  Pagination as TPagination
} from '@yes.technology/react-toolkit'

type PaginationProps = {
  className?: string
  pagination?: TPagination
  totalItems?: number
  setCurrentPage: (page: number) => void
  hierarchyLevel?: 'level_1' | 'level_2' | 'level_3'
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  currentValueEditable?: boolean
  showTotalPages?: boolean
}

export default function Pagination({
  className,
  pagination,
  totalItems = 0,
  setCurrentPage,
  hierarchyLevel = 'level_2',
  navigationSliderBackgroundColor,
  navigationSliderColor,
  currentValueEditable = false,
  showTotalPages = false
}: PaginationProps) {
  const pageSize = pagination?.limit || 10

  const totalPages = useMemo(
    () => Math.ceil(totalItems / pageSize),
    [totalItems, pageSize]
  )

  const offset = pagination?.offset || 0
  const currentPage = offset / pageSize + 1 || 1

  const onPageChange = (nextPage: number) => {
    const isPageValid = nextPage > 0 && nextPage <= totalPages
    isPageValid && setCurrentPage(nextPage)
  }

  if (!totalItems) {
    return null
  }

  return (
    <NavigationSlider
      currentPosition={currentPage}
      first={1}
      total={1}
      hierarchyLevel={hierarchyLevel}
      textForCurrent='Página'
      afterClick={onPageChange}
      hasPreviousPage={pagination?.hasPrevious}
      hasNextPage={pagination?.hasNext}
      className={className}
      arrowOrientationButtons='verticalArrows'
      heightClass='small'
      showTotalPages={showTotalPages}
      backgroundColor={navigationSliderBackgroundColor}
      color={navigationSliderColor}
      currentValueEditable={currentValueEditable}
    />
  )
}

import { NavigationSliderSecondary } from '@yes.technology/react-toolkit'

import { NavigationSliderSecondaryContainerProps } from './NavigationSliderSecondaryContainer.types'

const DataNavigationSiderSecondary = ({
  props
}: NavigationSliderSecondaryContainerProps) => (
  <NavigationSliderSecondary {...props} />
)

export default DataNavigationSiderSecondary

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FilterQuery, SortOption } from '@yes.technology/react-toolkit'

import { useObjectclass } from 'objectclass/shared'
import { useFetchYesObjects } from 'yesObject/shared'
import DataTable from './DataTable'
import { useSiteState } from 'siteState/shared'
import useTableLayouts from './hooks/useTableLayouts'
import { useSitemodel } from 'site/Render/hooks'
import useTableMode from './hooks/useTableMode'
import useChangedFieldValues from './hooks/useChangedFieldValues'
import Report from 'report'
import DataTableAreaLine from './DataTableAreaLine'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { merge } from 'lodash'

type DataTablesProps = {
  props: {
    uuid_objectclass: string
    uuid_layout_classification?: string
    uuid_filteraction?: string
    objectclassQueryString?: string
    informational?: boolean
    concat_fields?: ConcatFields
    hide_row_action_buttons?: boolean
    hide_main_collapsible_container?: boolean
    merge_filter_query?: FilterQuery
  }
}

const DataTables = ({ props }: DataTablesProps) => {
  const sitemodel = useSitemodel()

  const {
    uuid_objectclass: objectclassUuid,
    uuid_layout_classification: layoutClassificationUuid,
    uuid_filteraction: filteractionUuid,
    objectclassQueryString,
    informational = false,
    hide_main_collapsible_container: hideMainCollapsibleContainer = false,
    hide_row_action_buttons: hideRowActionButtons = false,
    merge_filter_query: mergeFilterQuery
  } = props

  const { objectclass, isLoading: isLoadingObjectClass } = useObjectclass({
    objectclassUuid
  })

  const { fields = {} } = objectclass || {}

  const columns = useMemo(() => Object.keys(fields), [fields])

  const [filterQuery] = useSiteState<FilterQuery>('filter-query')

  const finalFilterQuery = useMemo(() => {
    if (filterQuery && mergeFilterQuery) {
      return { ...merge(filterQuery, mergeFilterQuery) }
    }
    return filterQuery
  }, [filterQuery, mergeFilterQuery])

  const [_, setObjectclassUUID] = useSiteState<string>('objectclassuuid')
  const [shouldResetObjectArea, setShouldResetObjectArea] = useState(true)

  const columnsLayouts = useTableLayouts({ layoutClassificationUuid })

  const orderBy = columnsLayouts[0].defaultOrderBy.map(
    ([key, value]) => ({ [key]: value }) as SortOption
  )

  const columnsToRemove = columnsLayouts?.[0]?.removeColumns ?? []

  columnsToRemove.forEach((columnToRemove) => {
    const index = columns.indexOf(columnToRemove)
    if (index > -1) {
      columns.splice(index, 1)
    }
  })

  const {
    yesObjects,
    isFetching: isLoadingObjects,
    pagination,
    totalObjects,
    setPage,
    sortOptions,
    setSortOptions,
    fetchYesObjects
  } = useFetchYesObjects({
    filterBody: {
      query: finalFilterQuery,
      objectclass: objectclassUuid
    },
    filteractionUuid,
    enabled: !!finalFilterQuery || !!filteractionUuid
  })

  const { mode, setMode } = useTableMode()
  const { changedFieldValues, setChangedFieldValues } = useChangedFieldValues()

  const [reportDownloadTriggered, setReportDownloadTriggered] = useState(false)
  const triggerReportDownload = useCallback(
    () => setReportDownloadTriggered(true),
    []
  )

  const [lastReportUrl, onUrlReady] = useState<string>('')
  const reportUrl = reportDownloadTriggered ? lastReportUrl : ''

  const sitemodelTitle = sitemodel?.title
  const objectclassName = objectclass?.des
  const title = sitemodelTitle || objectclassName || ''

  const prevOrderBy = useRef({})

  useEffect(() => {
    if (JSON.stringify(prevOrderBy.current) !== JSON.stringify(orderBy)) {
      setSortOptions(orderBy)
      prevOrderBy.current = orderBy
    }
  }, [orderBy, setSortOptions])

  useEffect(() => {
    setShouldResetObjectArea(true)
  }, [sortOptions, finalFilterQuery, pagination])

  useEffect(() => {
    objectclassUuid && setObjectclassUUID(objectclassUuid)
  }, [objectclassUuid, setObjectclassUUID])

  const preventRendering =
    (!finalFilterQuery && !filteractionUuid) ||
    (finalFilterQuery && filteractionUuid)

  if (preventRendering)
    return (
      <DataTableAreaLine
        hierarchyLevel='level_2'
        title={title}
        titleOnly
        disabled
        hideMainCollapsibleContainer
      />
    )

  const isLoading = isLoadingObjectClass || isLoadingObjects

  if (isLoading && reportDownloadTriggered) {
    setReportDownloadTriggered(false)
  }

  return (
    <>
      <DataTable
        idObjectClass={objectclassUuid}
        objects={yesObjects}
        refetchObjects={fetchYesObjects}
        onSort={setSortOptions}
        setCurrentPage={setPage}
        actionColumnsPosition='last'
        columnsLayouts={columnsLayouts?.[0]?.columnsLayouts}
        concatFields={props.concat_fields}
        displayLayoutSelection={!informational}
        {...{
          isLoading,
          informational,
          title,
          objectclass,
          columns,
          fields,
          pagination,
          totalObjects,
          sortOptions,
          layoutClassificationUuid,
          mode,
          setMode,
          changedFieldValues,
          setChangedFieldValues,
          reportDownloadTriggered,
          triggerReportDownload,
          reportUrl,
          objectclassQueryString,
          shouldResetObjectArea,
          setShouldResetObjectArea,
          hideMainCollapsibleContainer,
          hideRowActionButtons
        }}
      />
      {reportDownloadTriggered && (
        <Report
          reportType='list'
          downloadType='single-pdf'
          {...{ layoutClassificationUuid, filteractionUuid, onUrlReady }}
        />
      )}
    </>
  )
}

export default DataTables

import { APIProvider, ControlPosition, Map } from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useFetchYesObjects } from 'yesObject/shared'
import { MapContainerProps } from './MapContainer.types'
import { useGeolocation } from 'shared/hooks'
import { CustomMapControl } from './CustomMapControl'
import { usePlace } from './hooks'
import { MapHandler } from './MapHandler'
import { MapMarker } from './MapMarker'
import { NoDataFound } from './NoDataFound'
import { YesLoading } from '@yes.technology/react-toolkit'

const MapContainer = ({ props }: MapContainerProps) => {
  const {
    uuid_objectclass: objectclassUuid,
    uuid_filteraction: filteractionUuid,
    message_no_data_found: messageNoDataFound
  } = props

  const {
    setSelectedPlace,
    cityUuid,
    selectedPlace,
    isLoading: isLoadingPlace
  } = usePlace()

  const filterQuery = useMemo(
    () => ({
      $and: [
        {
          'situation.des': {
            $eq: '40'
          },
          'cidade.uuid': {
            $in: [cityUuid]
          }
        }
      ]
    }),
    [cityUuid]
  )

  const {
    yesObjects,
    isLoading: isLoadingObjects,
    fetchYesObjects
  } = useFetchYesObjects({
    filterBody: {
      query: filterQuery,
      objectclass: objectclassUuid,
      project_fields: {
        address: 1,
        des: 1,
        gruposalvos: 1,
        cidade: 1,
        estado: 1,
        workingschedule: 1,
        documento: 1,
        uuid: 1
      }
    },
    initialPagination: {
      limit: 99999,
      offset: 0
    },
    filteractionUuid,
    enabled: false
  })

  useEffect(() => {
    if (cityUuid) fetchYesObjects()
  }, [cityUuid, fetchYesObjects])

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (selectedPlace && scrollRef.current) {
      const offsetTop =
        scrollRef.current.getBoundingClientRect().top + window.scrollY
      const marginTop = 20
      window.scrollTo({ top: offsetTop - marginTop, behavior: 'smooth' })
    }
  }, [selectedPlace])

  useEffect(handleScroll, [handleScroll])

  const initialCamera = {
    center: { lat: -13.9763943, lng: -54.8382792 },
    zoom: 10
  }

  const defaultCenter: google.maps.LatLngLiteral = initialCamera.center

  const { currentPosition } = useGeolocation()

  const handleSelectPlace = (place: google.maps.places.PlaceResult | null) =>
    setSelectedPlace(place)

  const isNoDataFound =
    messageNoDataFound &&
    !isLoadingObjects &&
    cityUuid &&
    yesObjects.length === 0

  const isLoading = isLoadingObjects || isLoadingPlace

  return (
    <APIProvider
      apiKey={window.googleCloudApikey}
      libraries={['marker']}
      onLoad={() => console.log('Maps API has loaded.')}
    >
      <div className='position-relative' ref={scrollRef}>
        <Map
          mapId={'9a918fb0ecc441c5'}
          defaultZoom={initialCamera.zoom}
          defaultCenter={currentPosition || defaultCenter}
          gestureHandling={'greedy'}
          reuseMaps
          zoomControl
          disableDefaultUI
          style={{ width: '100%', height: '600px' }}
        >
          {!isLoadingObjects &&
            yesObjects?.map((object) => (
              <MapMarker key={object.uuid} object={object} />
            ))}
        </Map>

        <CustomMapControl
          controlPosition={ControlPosition.TOP_LEFT}
          onPlaceSelect={handleSelectPlace}
        />

        <MapHandler markers={yesObjects} place={selectedPlace} />

        {isNoDataFound && <NoDataFound message={messageNoDataFound} />}

        {isLoading && (
          <div className='loading-place-map'>
            <YesLoading />
          </div>
        )}
      </div>
    </APIProvider>
  )
}

export default MapContainer

export const filterObjectByKeys = (
  object: Record<string, any> = {},
  keys: Record<string, any> = {}
): Record<string, any> =>
  Object.keys(object)
    .filter((key) => keys && keys.hasOwnProperty(key))
    .reduce((acc: Record<string, any>, key: string) => {
      acc[key] = object[key]
      return acc
    }, {})

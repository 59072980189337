import { YesObject } from '@yes.technology/react-toolkit'

export interface ConcatFields {
  [fieldname: string]: string
}

export function concatenateField(object: YesObject, sourcemap: string): string {
  const pattern = /\{\{([^\}]+)\}\}/g

  return sourcemap.replace(pattern, (match, p1) => {
    const keys = p1.split('.')
    const value = getValueFromObject(object, keys)
    if (value !== undefined) {
      return value
    }
    return ''
  })
}

function getValueFromObject(
  obj: YesObject,
  keys: string[]
): string | undefined {
  let value: any = obj
  for (const key of keys) {
    if (value?.[key] !== undefined) {
      value = value[key]
    } else {
      return undefined
    }
  }
  return typeof value === 'string' ? value : undefined
}

export function concatenateFields(
  concatFields: ConcatFields,
  yesObject: YesObject
) {
  return Object.keys(concatFields || {}).reduce((acc, fieldname) => {
    if (concatFields[fieldname]) {
      return {
        ...acc,
        [fieldname]: concatenateField(yesObject, concatFields[fieldname])
      }
    }
    return acc
  }, yesObject)
}

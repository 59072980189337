import {
  FilteractionObject,
  Pagination,
  SortOption
} from '@yes.technology/react-toolkit'

import defaultFetchYesObjects, {
  FetchYesObjectsResponse
} from './fetchYesObjects'
import defaultFetchFilteractionFilterbody, {
  DEFAULT_ORDER_BY,
  DEFAULT_PAGINATION
} from 'filteraction/shared/api/fetchFilteractionFilterbody'

type PerformFetchArgs = {
  filteraction: FilteractionObject
  showLoading?: boolean
  pagination?: Pagination
  orderby?: SortOption[]
  fetchFilteractionFilterbody?: typeof defaultFetchFilteractionFilterbody
  fetchYesObjects?: typeof defaultFetchYesObjects
}

export async function fetchYesObjectsWithFilteraction({
  filteraction,
  pagination = DEFAULT_PAGINATION,
  orderby = DEFAULT_ORDER_BY,
  showLoading = false,
  fetchYesObjects = defaultFetchYesObjects,
  fetchFilteractionFilterbody = defaultFetchFilteractionFilterbody
}: PerformFetchArgs): Promise<FetchYesObjectsResponse> {
  const filterBody = await fetchFilteractionFilterbody({
    filteraction,
    pagination,
    orderby
  })

  return fetchYesObjects({ filterBody, showLoading })
}

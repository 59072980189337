import { useState } from 'react'
import { HierarchyCard, Icon } from '@yes.technology/react-toolkit'

import { ExpandedButton, StyledChartWrapper } from '../Cockpit.styles'
import GoogleChart from './GoogleChart'
import { ChartProps, ChartTemplate } from '../Cockpit.types'
import { useTranslation } from 'react-i18n-lite'
import BrazilMap from './BrazilMap'
import BarChart from './BarChart'
import CardChart from './CardChart'

export type DataChartValue = string | number | null
export type DataChart = [string, DataChartValue, string?, string?, string?][]

const customCharts = ['BarChart', 'GeoChart', 'CardChart']

const hideExpandedButton = ['GeoChart', 'CardChart']

const ChartWrapper = ({
  uuid,
  des,
  dataset,
  template,
  column = 'col-md-12'
}: ChartProps) => {
  const { t } = useTranslation()

  const [expandedChart, setExpandedChart] = useState(false)

  const chartData: DataChart =
    typeof dataset === 'string' ? JSON.parse(dataset) : []

  const chartTemplate: ChartTemplate =
    typeof template === 'string' ? JSON.parse(template) : {}

  const hasData =
    chartData.length > 0 && chartData[0].length > 0 && chartData[0][0] !== ''

  if (!hasData) return null

  return (
    <StyledChartWrapper $expanded={expandedChart} className={`mb-3 ${column}`}>
      <HierarchyCard style={{ position: 'relative', height: '100%' }}>
        {!hideExpandedButton.includes(chartTemplate.chart_model) && (
          <ExpandedButton
            title={`${expandedChart ? t('cockpit.chart.collapse-button-label') : t('cockpit.chart.expand-button-label')}`}
            onClick={() => setExpandedChart(!expandedChart)}
          >
            <Icon
              iconName={`${expandedChart ? 'Contract' : 'Expand'}`}
              icSize='semiLarge'
            />
          </ExpandedButton>
        )}

        {chartTemplate.chart_model === 'BarChart' && (
          <BarChart
            chartModel={chartTemplate.chart_model}
            data={chartData}
            options={chartTemplate.options}
            title={des}
          />
        )}

        {chartTemplate.chart_model === 'CardChart' && (
          <CardChart
            chartModel={chartTemplate.chart_model}
            data={chartData}
            title={des}
          />
        )}

        {chartTemplate.chart_model === 'GeoChart' && (
          <BrazilMap
            chartModel={chartTemplate.chart_model}
            data={chartData}
            options={chartTemplate.options}
            title={des}
          />
        )}

        {!customCharts.includes(chartTemplate.chart_model) && (
          <GoogleChart
            chartUuid={uuid}
            chartModel={chartTemplate.chart_model}
            data={chartData}
            options={chartTemplate.options}
            title={des}
          />
        )}
      </HierarchyCard>
    </StyledChartWrapper>
  )
}

export default ChartWrapper

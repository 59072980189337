import { useCallback, useEffect, useState } from 'react'
import { useAsyncAutocomplete } from '@yes.technology/react-toolkit'

import fetchYesObjects from 'yesObject/shared/api/fetchYesObjects'
import useFetchSuggestions from '../useFetchSuggestions'

type UseSuggestionsOnChangeProps = {
  objectclassUuid: string
  initialValue?: string
  uuidCurrentSitemodelObjectclass?: string
  skipRelations?: boolean
  skipMetadata?: boolean
}

const useSuggestionsOnChange = ({
  objectclassUuid,
  initialValue = '',
  uuidCurrentSitemodelObjectclass = '',
  skipRelations = false,
  skipMetadata = false
}: UseSuggestionsOnChangeProps) => {
  const [value, onChange] = useState(initialValue)

  useEffect(() => {
    onChange(initialValue)
  }, [initialValue])

  const fetchSuggestions = useFetchSuggestions({
    objectclassUuid,
    fetchYesObjects,
    uuidCurrentSitemodelObjectclass,
    skipRelations,
    skipMetadata
  })

  const resetValue = useCallback((value = '') => {
    onChange(value)
  }, [])

  const { isLoading, suggestions } = useAsyncAutocomplete({
    query: value,
    fetchSuggestions
  })

  return {
    value,
    onChange,
    isLoading,
    suggestions,
    resetValue
  }
}

export default useSuggestionsOnChange
